html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

html,
body {
  font-weight: 400;
  background: linear-gradient(180deg, #0b0b1d 0%, #14142f 100%);
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-font-smoothing: antialiased;
}

button {
  border: none;
  background-color: transparent;
  transition: 0.5s ease;
  transition-property: color, background-color, border-color, box-shadow;
  cursor: pointer;
}

button:focus {
  outline: none;
}

a {
  transition: 0.5s ease;
  transition-property: color, background-color, border-color, box-shadow;
}

a:hover,
a:active,
a:focus {
  outline: none;
  text-decoration: none;
}

input,
textarea,
select {
  padding: 0;
  margin: 0;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  box-shadow: none;
  transition: 0.5s ease;
  transition-property: color, border-color, box-shadow;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

select::-ms-expand {
  display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

img {
  user-select: none;
}

a {
  user-select: none;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

::-moz-selection {
  background: #fff;
  color: #534f77;
  text-shadow: none;
}

::selection {
  background: #fff;
  color: #534f77;
  text-shadow: none;
}

::-webkit-input-placeholder {
  color: #bdbdbd;
  opacity: 1;
}

::-moz-placeholder {
  color: #bdbdbd;
  opacity: 1;
}

:-moz-placeholder {
  color: #bdbdbd;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #bdbdbd;
  opacity: 1;
}

:focus {
  outline: -webkit-focus-ring-color auto 0px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #534f77;
}

::-webkit-scrollbar-thumb {
  background-color: #bdbdbd;
  outline: 1px solid #534f77;
  border-radius: 4px;
}

:focus-visible {
  outline: 0px dotted;
}

.row--grid {
  margin: 0;
}

.row--grid .col-6,
.row--grid .col-12 {
  padding-left: 10px;
  padding-right: 10px;
}

@media (min-width: 768px) {

  .row--grid .col-6,
  .row--grid .col-12 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* .owl-carousel .owl-item {
  -webkit-transform: none;
} */
@media (min-width: 1440px) {
  .container {
    max-width: 1224px;
  }
}

/*==============================
	Header
==============================*/
.header__search {
  position: relative;
  display: flex;
  width: 100%;
  max-width: 380px;
  transition: top 0.5s ease;
  padding: 0;
}

.header__search input {
  border: 1px solid rgba(255, 255, 255, 0.33);
  border-radius: 4px;
  font-size: 14px;
  color: #fff;
  height: 44px;
  background-color: transparent;
  font-weight: 400;
  padding: 0 60px 0 20px;
  width: 100%;
}

.header__search input:focus {
  border-color: #fff;
}

.header__search button {
  position: absolute;
  top: 8px;
  right: 20px;
}

.header__search button svg {
  width: 20px;
  height: auto;
  fill: #bdbdbd;
  transition: 0.5s ease;
}

.header__search button:hover svg {
  fill: #6164ff;
}

@media (max-width: 450px) {
  .header__search {
    max-width: 450px !important;
  }
}

@media (max-width: 768px) {
  .header__search input {
    display: flex;
    padding: 15px;
  }

  .header__search input::placeholder {
    color: rgba(255, 255, 255, 0.33);
    letter-spacing: 0.02em;
    font-size: 14px;
    font-weight: 400;
  }

  .header__search button {
    right: 15px;
  }

  .header__search button svg {
    fill: rgba(255, 255, 255, 0.33);
  }
}

.header__profile-menu {
  display: block;
  position: absolute !important;
  z-index: -1;
  pointer-events: none;
  opacity: 0;
  top: 0;
  background-color: #0e0e24;
  border-radius: 8px;
  padding: 20px;
  min-width: 190px;
  transition: opacity 0.5s ease;
  transform: translate3d(0px, 54px, 0px) !important;
  height: auto;
  border: 1px solid rgba(255, 255, 255, 0.33);
  right: 0 !important;
  left: auto !important;
  margin-top: 2px;
}

@media (max-width: 1250px) {
  .header__profile-menu {
    right: 5px !important;
  }
}

.header__profile-menu li {
  margin-bottom: 15px;
  width: 100%;
}

.header__profile-menu img {
  width: 16px;
  height: 16px;
  margin-right: 15px;
}

.header__profile-menu span {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.03em;
  color: rgba(255, 255, 255, 0.66);
}

.header__profile-menu span:hover {
  color: rgba(255, 255, 255, 1);
}

.header__profile-menu {
  padding-top: 15px;
  padding-bottom: 15px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  margin-bottom: 0;
}

.header__profile-menu a {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: #bdbdbd;
  font-size: 14px;
  font-weight: 400;
  transition: color 0.5s ease;
  position: relative;
}

.header__profile-menu a svg {
  fill: #fff;
  width: 20px;
  height: auto;
  transition: fill 0.5s ease;
  margin-right: 10px;
}

.header__profile-menu a:hover {
  color: #fff;
}

.header__profile-menu a:hover svg {
  fill: #6164ff;
}

.header__profile-menu.show {
  z-index: 1000;
  pointer-events: auto;
  opacity: 1;
}

.header__menu-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  height: 60px;
  border-bottom: 0.5px solid rgba(121, 126, 137, 0.5);
}

.header__menu-item--container {
  display: flex;
  width: 100%;
  align-items: center;
  border: none !important;
}

/*==============================
	Main
==============================*/
.main {
  position: relative;
  margin-top: 32px;
  padding: 0 0 60px;
  z-index: 999;
}

.main__title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-bottom: 10px;
  margin-top: 60px;
}

.main__title h1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  font-weight: 400;
  color: #fff;
  font-size: 30px;
  line-height: 140%;
  margin-bottom: 0;
  position: relative;
}

.main__title h1 b {
  font-weight: 500;
}

.main__title h1 a {
  color: #fff;
}

.main__title h1 a:hover {
  color: #6164ff;
}

.main__title h2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  font-weight: 400;
  color: #fff;
  font-size: 26px;
  line-height: 150%;
  margin-bottom: 0;
  position: relative;
}

.main__title h2 svg {
  width: 26px;
  height: auto;
  fill: #6164ff;
  margin-right: 10px;
}

.main__title h2 b {
  font-weight: 500;
}

.main__title h2 a {
  color: #fff;
}

.main__title h2 a:hover {
  color: #6164ff;
}

.main__title h3 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  font-weight: 400;
  font-size: 24px;
  color: #fff;
  margin-bottom: 0;
  line-height: 100%;
  position: relative;
}

.main__title p {
  display: block;
  width: 100%;
  font-size: 16px;
  line-height: 26px;
  color: #bdbdbd;
  margin-top: 20px;
  margin-bottom: 0;
}

.main__title p b {
  font-weight: 600;
}

.main__title p a {
  color: #6164ff;
  text-decoration: underline;
}

.main__title p a:hover {
  text-decoration: none;
}

.main__title ol {
  padding-left: 0;
  list-style: none;
  counter-reset: li;
  margin-bottom: 0;
}

.main__title ol ol {
  padding-left: 15px;
  margin-top: 10px;
}

.main__title ol ol ol {
  margin-top: 10px;
  margin-bottom: 10px;
}

.main__title ol ol ol li {
  margin-top: 5px;
}

.main__title ol h4 {
  font-size: 16px;
  color: #fff;
  display: inline-block;
  margin-bottom: 0;
  margin-top: 20px;
  font-weight: 500;
}

.main__title ol li {
  font-size: 16px;
  line-height: 24px;
  color: #bdbdbd;
  position: relative;
}

.main__title ol li b {
  font-weight: 600;
  color: #bdbdbd;
}

.main__title ol li a {
  color: #eb5757;
}

.main__title ol li a:hover {
  color: #eb5757;
  text-decoration: underline;
}

.main__title ol li:last-child {
  margin-bottom: 0;
}

.main__title ol li:before {
  counter-increment: li;
  content: counters(li, ".") ". ";
}

.main__title--page {
  margin-bottom: 0;
  margin-top: 50px;
}

.owl-dots {
  flex-direction: row;
  width: 100%;
  position: relative;
}

.owl-dot span {
  display: block;
  height: 4px;
  width: 10px;
  border-radius: 2px;
  background-color: #bdbdbd;
  transition: 0.5s ease;
}

.owl-dot.active span {
  width: 20px;
  background-color: #6164ff;
}

.main__author {
  display: block;
  position: relative;
  height: 300px;
  background: rgba(255, 255, 255, 0.33);
  border-radius: 12px 12px 0px 0px;
  z-index: 1;
  background-image: url("https://backstage.global/img/bg/bg.png");
}

@media (max-width: 1256px) {
  .main__author {
    margin: 0 16px;
  }

  .profile__container {
    margin: 0 16px;
  }
}

@media (max-width: 576px) {
  .main__author {
    height: 224px;
    margin: 0 16px;
  }
}

@media (min-width: 768px) {
  .main {
    padding: 0 0 70px;
  }

  .main__title {
    margin-top: 82px;
    margin-bottom: 0;
  }

  .main__title h1 {
    font-size: 36px;
  }

  .main__title h2 {
    font-size: 30px;
  }

  .main__title h2 svg {
    width: 30px;
  }

  .main__title p:last-child {
    margin-bottom: 10px;
  }

  .main__title--page {
    margin-top: 50px;
  }

  .owl-dots {
    margin-top: 30px;
  }
}

/*==============================
	Breadcrumb
==============================*/
.breadcrumb {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 25px;
}

.breadcrumb__item {
  font-size: 14px;
  color: #bdbdbd;
  transition: 0.5s ease;
  position: relative;
  margin-right: 34px;
}

.breadcrumb__item:before {
  content: "";
  position: absolute;
  left: 100%;
  top: 1px;
  bottom: 0;
  width: 34px;
  background: url("https://backstage.global/img/breadcrumb.svg") no-repeat center/16px auto;
}

.breadcrumb__item:hover {
  color: #bdbdbd;
}

.breadcrumb__item:last-child {
  margin-right: 0;
}

.breadcrumb__item:last-child:before {
  display: none;
}

.breadcrumb__item--active {
  cursor: default;
  margin-right: 0;
}

.breadcrumb__item--active:before {
  display: none;
}

.breadcrumb__item--active:hover {
  color: #bdbdbd;
}

.breadcrumb a {
  color: #bdbdbd;
}

.breadcrumb a:hover {
  color: #6164ff;
}

/*==============================
	Сard
==============================*/

@media (max-width: 288px) {
  .explorer__top-buttons {
    flex-direction: column;
    gap: 10px !important;
  }

  .collection {
    height: 100% !important;
    width: 100% !important;
  }
}

.carousel-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-wrapper .owl-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px !important;
}

.carousel-wrapper .owl-dot span {
  display: block;
  height: 2px !important;
  width: 30px !important;
  background-color: rgba(255, 255, 255, 0.33) !important;
  transition: background-color 0.5s ease;
}

.carousel-wrapper .owl-dot.active span {
  background-color: #6a4dfd !important;
}

.owl-theme {
  width: 100% !important;
}

.explorer__container {
  margin-top: 70px;
  margin-bottom: 70px;
  padding: 0;
  z-index: 999;
  max-width: 1224px;
  width: 100%;
}

@media (max-width: 1235px) {
  .explorer__container {
    padding: 0 10px;
  }
}

.explorer__top {
  display: flex;
  flex-direction: column;
  z-index: 555;
}

.explorer__top-title {
  font-size: 40px;
  color: #fff;
  font-weight: 700;
}

.explorer__top-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 20px;
}

.explorer__top-tags {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.explorer__top-tags button {
  display: flex;
  align-items: center;
  text-align: center;
  margin-bottom: 0px;
  height: 44px;
  padding: 7px 20px;
  border-radius: 22px;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.02em;
  color: #fff;
  z-index: 10;
}

.category__inactive--tag {
  border: 1px solid rgba(255, 255, 255, 0.66);
}

.category__active--tag {
  border: 1px solid #6a4dfd;
  background-color: #6a4dfd;
}

.explorer__top-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
}

.explorer__top-btn--group {
  position: relative;
}

.explorer__top-btn-opts {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
  right: 0;
  top: 55px;
  padding: 20px 18px;
  z-index: 555;
  background: #0d0d21;
  border: 1px solid rgba(255, 255, 255, 0.33);
  border-radius: 8px;
  width: 200px;
  z-index: 99999;
}

@media (max-width: 450px) {
  .explorer__top-btn-opts {
    gap: 5px;
  }
}

.explorer__top-btn-opts-mb {
  position: absolute;
  display: flex;
  gap: 5px;
  flex-direction: column;
  justify-content: space-between;
  left: 0;
  top: 55px;
  padding: 20px 23px;
  z-index: 555;
  background: #0d0d21;
  border: 1px solid rgba(255, 255, 255, 0.33);
  border-radius: 8px;
  width: 196px;
}

.report__top-btn-opts {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
  right: 0;
  top: 55px;
  padding: 20px 23px;
  z-index: 555;
  background: #0d0d21;
  border: 1px solid rgba(255, 255, 255, 0.33);
  border-radius: 8px;
  width: 196px;
  height: 170px;
  z-index: 99999;
}

.report__top-btn-opts-mb {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  right: 0;
  top: 55px;
  padding: 20px 23px;
  z-index: 555;
  background: #0d0d21;
  border: 1px solid rgba(255, 255, 255, 0.33);
  border-radius: 8px;
  width: 196px;
  height: 170px;
}

.explorer__top-checkbox {
  z-index: 666;
}

.explorer__top-checkbox input:not(:checked)+label,
.explorer__top-checkbox input:checked+label {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.03em;
  color: #fff;
  position: relative;
  cursor: pointer;
  padding-left: 35px;
  line-height: 22px;
  margin: 0;
}

.explorer__top-checkbox input:not(:checked)+label:before,
.explorer__top-checkbox input:checked+label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 1px solid rgba(255, 255, 255, 0.33);
  border-radius: 4px;
}

.explorer__top-checkbox input:not(:checked)+label:after,
.explorer__top-checkbox input:checked+label:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  text-align: center;
  transition: 0.3s ease;
  background: url("https://backstage.global/img/check_white.svg") no-repeat center/20px auto;
  border-radius: 4px;
}

.explorer__top-checkbox input:not(:checked)+label:after {
  opacity: 0;
  transform: scale(0);
}

.explorer__top-checkbox input:checked+label:after {
  opacity: 1;
  transform: scale(1);
  background-color: #6a4dfd;
  width: 20px;
  height: 20px;
}

.explorer__top-buttons button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  width: 125px;
  height: 44px;
  border-radius: 4px;
  color: white;
}

.report__top-buttons button {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 10px;
  width: 125px;
  height: 44px;
  border-radius: 4px;
  color: white;
}

.report__top-active--btn {
  border: 1px solid #6a4dfd;
}

.report__top-inactive--btn {
  border: 1px solid rgba(255, 255, 255, 0.66);
}

.explorer__top-active--btn {
  border: 1px solid #6a4dfd;
}

.explorer__top-inactive--btn {
  border: 1px solid rgba(255, 255, 255, 0.66);
}

.explorer__btn-text {
  margin: 0px;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.15em;
  text-transform: uppercase;
}

.card__author {
  display: inline;
  width: 100%;
  position: relative;
}

.card__author--verified:after {
  content: "";
  position: absolute;
  display: block;
  width: 18px;
  height: 18px;
  border: 2px solid #fff;
  bottom: -2px;
  left: 24px;
  border-radius: 50%;
  background: url("https://backstage.global/img/verified.svg") no-repeat center #2f80ed;
  background-size: 14px auto;
  z-index: 1;
  pointer-events: none;
}

.card__author img {
  display: block;
  overflow: hidden;
  width: 24px !important;
  height: 24px;
  border-radius: 16px;
  margin-right: 10px;
}

.card__author a {
  font-weight: 700;
  color: white;
  letter-spacing: 0.03rem;
  font-size: 14px;
}

.card__author span {
  font-weight: 700;
  color: white;
  letter-spacing: 0.03rem;
  font-size: 14px;
}

.card__author a:hover {
  color: #6164ff;
}

.card__likes {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.card__likes svg {
  width: 18px;
  height: auto;
  transition: fill 0.5s ease;
  fill: #bdbdbd;
}

.card__likes span {
  font-size: 13px;
  line-height: 18px;
  color: #bdbdbd;
  margin-left: 5px;
  transition: color 0.5s ease;
}

.card__likes span:empty {
  margin-left: 0;
}

.card__likes:hover svg {
  fill: #eb5757;
}

@media (max-width: 620px) {
  .explorer__top-main {
    flex-direction: column;
    width: 100%;
    gap: 20px;
  }

  .explorer__top {
    align-items: center;
  }
}

@media (max-width: 576px) {
  .explorer__top-main {
    width: 288px;
  }
}

@media (max-width: 680px) {
  .explorer__top-tags button {
    height: 40px;
    padding: 0px 15px;
    font-size: 12px;
  }
}

@media (max-width: 576px) {

  /* .owl-theme {
    width: 95vw !important;
  } */
  .explorer__top {
    align-items: center;
    width: 100%;
    padding-left: 25px;
    padding-right: 25px;
  }

  .explorer__top-title {
    font-size: 35px;
  }

  .explorer__top-tags button {
    height: 30px;
    padding: 0px 10px;
    font-size: 12px;
    letter-spacing: 0;
  }

  .explorer__top-tags button:last-child {
    margin-right: 0px;
  }

  .explorer__top-buttons {
    justify-content: space-between;
  }

  .explorer__top-buttons button {
    margin-left: 0px;
    padding: 0 15px;
    width: 115px;
  }

  .explorer__top-buttons button:last-child {
    margin-right: 0px;
  }

  .explorer__top-btn--group {
    justify-content: space-between;
  }
}

/*==============================
	Asset
==============================*/
.buy__btn {
  margin-left: 25px;
  flex: 1;
  height: 44px;
  border-radius: 4px;
  background-color: #6a4dfd;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 16px;
  z-index: 2;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.15em;
  margin-top: 0px;
  margin-bottom: 0px;
}

.buy__btn-disable {
  margin-left: 25px;
  flex: 1;
  height: 44px;
  border-radius: 4px;
  background-color: rgba(106, 77, 253, 0.33);
  display: flex;
  justify-content: center;
  align-items: center;
  color: gray;
  font-size: 16px;
  z-index: 2;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.15em;
  margin-top: 0px;
  margin-bottom: 0px;
}

.buy__btn:hover {
  margin-top: -2px;
  filter: drop-shadow(0px 0px 8px #6a4dfd);
}

.asset__author-item {
  width: 50%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.asset__author-first-item {
  width: 33%;
}

.text__small-time {
  font-size: 12px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.66);
  text-transform: uppercase;
  letter-spacing: 2px;
  margin: 0;
}

.text__small-tax {
  text-align: end;
}

.asset__btn {
  width: 100%;
  height: 44px;
  border-radius: 4px;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #6a4dfd;
  color: #fff;
  letter-spacing: 0.15em;
  font-size: 16px;
  position: relative;
  z-index: 2;
  text-transform: uppercase;
  font-weight: 700;
  padding: 0 20px;
  margin-top: 15px;
}

.asset__btn:hover {
  margin-top: 13px;
  margin-bottom: 2px;
  filter: drop-shadow(0px 0px 8px #6a4dfd);
  color: #fff;
}

.swish__btn {
  background-color: white;
}

.swish__btn:hover {
  filter: drop-shadow(0px 0px 8px #fff);
}

@media (max-width: 576px) {
  .asset__author-item {
    width: 50%;
  }
}

@media (max-width: 400px) {
  .asset__author-item {
    width: 100%;
  }
}

/*==============================
	Sellers-list
==============================*/
.sellers-list__container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.sellers-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 24%;
}

.sellers-list__item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 60px;
  width: 100%;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  margin-top: 20px;
  margin-bottom: 0px;
  padding-left: 15px;
}

.sellers-list__item:hover {
  margin-top: 18px;
  margin-bottom: 2px;
  box-shadow: 0px 0px 8px #6a4dfd;
}

.sellers-hidden {
  display: flex;
}

.sellers-list__number {
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  margin: 0;
}

.sellers-list__author {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sellers-list__author--verified {
  width: 16px;
  height: 16px;
  border: 1px solid #fff;
  margin-left: 5px;
  border-radius: 50%;
  background: url("https://backstage.global/img/verified.svg") no-repeat center #2f80ed;
  z-index: 1;
  pointer-events: none;
  display: inline-block;
}

.sellers-list__btn {
  font-size: 16;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.15em;
  font-weight: 700;
  height: 44px;
  width: 173px;
  margin-top: 35px;
  margin-bottom: 10px;
  border: 1px solid rgba(255, 255, 255, 0.66);
  border-radius: 4px;
}

.sellers-list__btn:hover {
  filter: drop-shadow(0px 0px 6px #ffffff);
  margin-top: 33px;
  margin-bottom: 12px;
}

.collection__description {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.66);
}

.collection__avatar-container {
  display: flex;
  gap: 13px;
  margin: 12px 0 18px 0;
  flex-wrap: wrap;
}

.collection__avatar-name {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.collection__author-meta {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 18px 40px 40px;
  background-color: #14142f;
  border: 1px solid rgba(255, 255, 255, 0.23);
  border-top-width: 0;
  border-radius: 0px 0px 12px 12px;
  height: auto;
}

.collection__author-avatar {
  position: relative;
  width: 68px;
  height: 68px;
  border-radius: 70px;
  align-items: center;
  overflow: hidden;
  background: #fff;
  z-index: 2;
}

.collection__author-avatar img {
  width: 100%;
  height: 100%;
}

.collection__author-name {
  display: inline;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 20px;
  color: #fff;
  font-weight: 700;
  margin: 5px 0px 0px;
  line-height: 24px !important;
  flex-wrap: wrap;
}

.collection__author-name a {
  color: #fff;
}

.collection__author-name a:hover {
  color: #6164ff;
}

.collection__author-nickname {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: left;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.66);
  font-weight: 400;
  margin-bottom: 0;
}

.collection__author-nickname a {
  color: #6a4dfd;
  font-size: 16px;
}

.collection__author-nickname a:hover {
  color: #fff;
}

.collection__author-description {
  font-weight: 400;
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.66);
}

.collection__author-key {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.66);
  margin-bottom: 5px;
}

.collection__author-val {
  font-weight: 700;
  font-size: 16px;
  line-height: 12px;
  letter-spacing: 0.5px;
  color: #fff;
}

.collection__card {
  margin-top: 30px;
  width: 270px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
}

.collection__card:hover {
  box-shadow: 0px 0px 8px #6a4dfd;
  margin-top: 28px;
}

.sellers-list__author img {
  overflow: hidden;
  width: 40px !important;
  height: 40px;
  border-radius: 20px;
  margin-left: 15px;
  margin-right: 15px;
}

.sellers-list__author a {
  font-weight: 700;
  color: #fff;
  font-size: 14px;
  letter-spacing: 0.02em;
}

.sellers-list__author a:hover {
  color: #6164ff;
}

@media (max-width: 576px) {
  .sellers-hidden {
    display: none;
  }

  .sellers-list {
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
  }

  .sellers-list__container {
    justify-content: center;
  }

  .collection__card {
    width: 100%;
  }

  .collection__author-meta {
    padding: 10px 25px 25px;
    margin-bottom: 20px;
  }
}

/*==============================
	Activity
==============================*/
.activity__block {
  margin-top: 16px;
  border-radius: 12px;
}

.activity {
  position: relative;
  display: flex;
  width: 100%;
  overflow: hidden;
  padding: 14px 12px 20px 12px;
  background: rgba(255, 255, 255, 0.03);
  z-index: 999;
}

.activity__top {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px 12px 0 0;
  display: flex;
  align-items: center;
}

.activity__bottom {
  padding: 0 12px 20px 12px;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 0 0 12px 12px;
}

.activity__img {
  padding: 10px;
  background: radial-gradient(50% 50% at 0% 0%,
      rgba(5, 255, 0, 0.33) 0%,
      rgba(5, 255, 0, 0) 210%);
  border-radius: 12px 0 0 0;
}

.activity__card-ago {
  color: rgba(255, 255, 255, 0.66);
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.5px;
  margin-bottom: 0px;
  margin-left: 7px;
}

.activity__filter--checkbox {
  width: 100%;
  text-align: left;
  border-top: none !important;
}

@media (max-width: 992px) {
  .activity {
    padding: 14px 17px;
  }

  .activity__bottom {
    padding: 14px 17px;
  }
}

@media (max-width: 576px) {
  .activity__block {
    margin: 16px auto 0 auto;
    max-width: 90%;
  }
}

@media (max-width: 992px) {
  .activity {
    align-items: flex-start;
  }
}

/*==============================
	Partners
==============================*/
.partners {
  margin-top: 60px;
}

@media (min-width: 768px) {
  .partners {
    margin-top: 70px;
  }
}

/*==============================
	Profile
==============================*/
.profile {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #14142f;
  padding: 20px 0 0 20px;
  border-radius: 16px;
  position: relative;
  margin-top: 20px;
  border: 1px solid #887bff;
}

.profile__author-meta {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 35px 35px;
  /* max-width: 390px; */
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 0px 0px 12px 12px;
  height: auto;
  position: relative;
}

.profile__author-avatar {
  position: relative;
  width: 140px;
  height: 140px;
  border-radius: 70px;
  align-items: center;
  margin: auto;
  margin-top: -70px;
  overflow: hidden;
  background: #fff;
  z-index: 2;
}

.profile__author-editer {
  position: absolute;
  right: calc(50% - 68px);
  top: 20px;
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  background-color: #6a4dfd;
  width: 36px;
  height: 36px;
}

.profile__author-editer:hover {
  cursor: pointer;
}

.profile__author-editer:hover img {
  cursor: pointer !important;
}

.profile__author-code {
  position: relative;
  background: rgba(255, 255, 255, 0.06);
  border-radius: 4px;
  height: 44px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  margin-bottom: 15px;
}

.profile__author-code img {
  width: 25px;
}

.profile__author-code ._grayimg {
  opacity: 0.5;
}

.profile__author-code ._img:hover {
  cursor: pointer !important;
  margin-bottom: 2px;
}

.profile__author-address {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 0px;
  margin-right: 10px;
  flex: 1;
  background: transparent;
  border: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.profile__author-links {
  display: flex;
  margin-top: 0px;
}

.profile__author-links a {
  margin-right: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 44px;
  height: 44px;
  background-color: rgba(255, 255, 255, 0.06);
  border-radius: 4px;
}

.profile__author-links img {
  width: 44px;
  height: 44px;
  margin-right: 15px;
}

.profile__settings-header {
  margin-top: 30px;
  margin-bottom: 35px;
}

.profile__settings-title {
  color: #fff;
  font-size: 32px;
  font-weight: 700;
  letter-spacing: 0.02em;
}

.profile__settings-tags {
  display: flex;
  border-bottom: 1px solid rgba(255, 255, 255, 0.33);
}

.profile__settings-tags p {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-right: 40px;
  margin-bottom: 0px;
  padding: 8px 15px;
}

.profile__settings-tags p:hover {
  cursor: pointer;
}

.profile__settings-active {
  color: #fff;
  border-bottom: 2px solid #6a4dfd;
}

.profile__settings-inactive {
  color: rgba(255, 255, 255, 0.33);
}

.input__upload {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0px;
  margin-top: 10px;
  border: 1px solid rgba(255, 255, 255, 0.33);
  border-radius: 4px;
  height: 44px;
  font-size: 16px;
  width: 100%;
  padding: 0 20px;
  color: #fff;
  background: transparent;
  outline: none;
  font-weight: 700;
  letter-spacing: 0.15em;
}

.input__upload:hover {
  cursor: pointer;
  border-color: white;
}

.input__upload-addon {
  border-style: dashed;
}

.input__upload-addon:hover {
  border-color: white;
}

@media (max-width: 576px) {
  .profile__author-meta {
    padding: 0 0 25px;
    margin-bottom: 20px;
    background-color: transparent;
  }

  .profile__author-editer {
    right: calc(50% - 75px);
  }

  .explorer__container {
    margin-top: 30px;
  }

  .explorer__top-tags {
    width: 100%;
    gap: 0;
  }
}

@media (min-width: 768px) {
  .profile {
    margin-top: 30px;
    padding: 0 20px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

/*==============================
	Article
==============================*/
.article__top {
  margin-bottom: 20px;
}

.article__top li {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.66);
  letter-spacing: 0.03em;
  font-weight: 400;
}

.article__message {
  display: flex;
  align-items: center;
}

.article__message img {
  width: 16px;
  height: 16px !important;
  margin: 0px 5px !important;
  background: none !important;
}

.article__message p {
  font-size: 12px !important;
  font-weight: 400;
  letter-spacing: 0.5px;
  color: rgba(255, 255, 255, 0.66);
}

@media (max-width: 576px) {
  .article__top {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .article__top p {
    font-size: 20px;
    font-weight: 700;
    color: #fff;
    letter-spacing: 0.03em;
    margin: 0px;
  }
}

/*==============================
	Sign
==============================*/
.sign {
  display: block;
  position: relative;
  z-index: 1000 !important;
  overflow: auto;
  max-width: 500px;
  margin: auto;
}

.sign__container {
  width: 100%;
}

.sign__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  padding: 25px 0 0;
}

.sign__form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  padding: 30px 10px;
  position: relative;
  width: 100%;
  max-width: 350px;
  margin-bottom: 50px;
}

.sign__form--contacts,
.sign__form--profile,
.sign__form--create {
  margin-top: 20px;
  max-width: 100%;
  padding: 20px 20px 5px;
  align-items: flex-start;
}

.sign__form--contacts .sign__btn,
.sign__form--profile .sign__btn,
.sign__form--create .sign__btn {
  margin: 0 0 15px;
}

.sign__form--contacts .row,
.sign__form--profile .row,
.sign__form--create .row {
  margin-left: -10px;
  margin-right: -10px;
}

.sign__form--contacts .col-12,
.sign__form--profile .col-12,
.sign__form--create .col-12 {
  padding-left: 10px;
  padding-right: 10px;
}

.sign__form--profile .sign__btn {
  margin: 5px 0 15px;
}

.sign__logo {
  display: block;
  margin-bottom: 30px;
}

.sign__logo a {
  max-width: 100%;
  width: auto;
}

.sign__logo img {
  width: auto;
  height: 50px;
}

.sign__main-title {
  font-size: 40px;
  font-weight: 700;
  color: #fff;
  letter-spacing: 0.03em;
  margin-top: 0px;
}

.sign__form-label {
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.05em;
  line-height: 12px;
  margin: 0px;
}

.sign__title {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 20px;
}

.sign__label {
  font-weight: 400;
  font-size: 14px;
  color: #bdbdbd;
  margin-bottom: 5px;
  padding-left: 20px;
}

.sign__label b {
  font-weight: 600;
  color: #fff;
}

.sign__input {
  margin-top: 10px;
  border: 1px solid rgba(255, 255, 255, 0.33);
  border-radius: 4px;
  height: 44px;
  font-size: 16px;
  width: 100%;
  padding: 0 20px;
  color: #fff;
  background: transparent;
  outline: none;
}

.download__btn {
  border-radius: 4px;
  height: 44px;
  font-size: 16px;
  width: 100%;
  padding: 0 20px;
  color: #fff;
  background: #6a4dfd;
  font-weight: 700;
  outline: none;
  width: 200px;
}

.download__btn:hover {
  filter: drop-shadow(0px 0px 8px #6a4dfd);
}

@media (max-width: 992px) {
  .download__btn {
    margin-top: 20px;
  }
}

.sign__input:focus {
  border: 2px solid #6a4dfd !important;
  filter: drop-shadow(0px 0px 8px #6a4dfd);
}

.sign__input:hover {
  border: 1px solid #ffffff;
  filter: drop-shadow(0px 0px 8px #6a4dfd);
}

.sign__select {
  margin-top: 10px;
  border: 1px solid rgba(255, 255, 255, 0.33);
  border-radius: 4px;
  height: 44px;
  font-size: 16px;
  width: 100%;
  padding: 0 20px;
  color: #fff;
  background: transparent;
  outline: none;
  cursor: pointer;
}

.sign__select:focus {
  border: 2px solid #6a4dfd !important;
  filter: drop-shadow(0px 0px 8px #6a4dfd);
}

.sign__select:hover {
  border: 2px solid #ffffff;
  filter: drop-shadow(0px 0px 8px #6a4dfd);
}

.sign__group {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: wrap;
  position: relative;
  margin-bottom: 20px;
  width: 100%;
}

.sign__group--row .sign__label {
  padding-left: 0;
}

.sign__group--checkbox {
  width: 100%;
  text-align: left;
  padding-top: 20px;
}

.sign__group--checkbox input:not(:checked),
.sign__group--checkbox input:checked {
  position: absolute;
  left: -9999px;
}

.sign__group--checkbox input:not(:checked)+label,
.sign__group--checkbox input:checked+label {
  font-size: 14px;
  color: #bdbdbd;
  font-weight: normal;
  position: relative;
  cursor: pointer;
  padding-left: 35px;
  line-height: 22px;
  margin: 0;
}

.sign__group--checkbox input:not(:checked)+label a,
.sign__group--checkbox input:checked+label a {
  color: #6164ff;
}

.sign__group--checkbox input:not(:checked)+label a:hover,
.sign__group--checkbox input:checked+label a:hover {
  color: #6164ff;
  text-decoration: underline;
}

.sign__group--checkbox input:not(:checked)+label:before,
.sign__group--checkbox input:checked+label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 2px;
  width: 20px;
  height: 20px;
  background-color: #534f77;
  border: 1px solid transparent;
  border-radius: 4px;
}

.sign__group--checkbox input:not(:checked)+label:after,
.sign__group--checkbox input:checked+label:after {
  content: "";
  position: absolute;
  left: 0;
  top: 2px;
  width: 16px;
  height: 16px;
  text-align: center;
  transition: 0.5s ease;
  background: url("https://backstage.global/img/check_white.svg") no-repeat center/20px auto;
  border-radius: 4px;
}

.sign__group--checkbox input:not(:checked)+label:after {
  opacity: 0;
  transform: scale(0);
}

.sign__group--checkbox input:checked+label:after {
  opacity: 1;
  transform: scale(1);
  background-color: #6a4dfd;
  width: 20px;
  height: 20px;
}

.sign__group--checkbox label::-moz-selection {
  background: transparent;
  color: #bdbdbd;
}

.sign__group--checkbox label::selection {
  background: transparent;
  color: #bdbdbd;
}

.sign__btn {
  width: 100%;
  height: 44px;
  border-radius: 4px;
  background-color: #6a4dfd;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 16px;
  z-index: 2;
  text-transform: uppercase;
  font-weight: 700;
  margin: 15px auto;
  letter-spacing: 0.15em;
  cursor: pointer;
}

.sign__btn-disable {
  width: 100%;
  height: 44px;
  border-radius: 4px;
  background-color: #6a4dfd54;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 16px;
  z-index: 2;
  text-transform: uppercase;
  font-weight: 700;
  margin: 15px auto;
  letter-spacing: 0.15em;
}

.sign__btn img {
  margin-right: 10px;
}

.sign__btn:hover {
  filter: drop-shadow(0px 0px 8px #6a4dfd);
  margin-top: 13px;
  margin-bottom: 17px;
}

.sign__btn-inactive {
  width: 100%;
  height: 44px;
  border-radius: 4px;
  background-color: #2f2570;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #585771;
  font-size: 16px;
  z-index: 2;
  text-transform: uppercase;
  font-weight: 700;
  margin: 15px auto;
  letter-spacing: 0.15em;
}

.sign__btn-second {
  width: 100%;
  height: 44px;
  border-radius: 4px;
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.33);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 16px;
  z-index: 2;
  text-transform: uppercase;
  font-weight: 700;
  margin: 15px auto;
  letter-spacing: 0.15em;
  cursor: pointer;
}

.sign__btn-second:hover {
  filter: drop-shadow(0px 0px 6px #ffffff);
  color: #fff;
}

.sign__btn-second img {
  margin-right: 10px;
}

.signUp__btn {
  width: 100%;
  height: 44px;
  border-radius: 30px;
  background-color: #6a4dfd;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 16px;
  z-index: 2;
  margin: 15px auto;
  letter-spacing: 0.05em;
  cursor: pointer;
  font-weight: 500;
}

.signUp__btn:hover {
  box-shadow: 0px 0px 8px #6a4dfd;
  color: #fff;
}

.signUp_social__btn {
  width: 100%;
  height: 44px;
  border-radius: 30px;
  border: 1px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 16px;
  z-index: 2;
  margin: 15px auto;
  letter-spacing: 0.05em;
  cursor: pointer;
  position: relative;
  font-weight: 500;
}

.signUp_social__btn:hover {
  box-shadow: 0px 0px 8px white;
}

.media__icon {
  width: 20px;
  height: 20px;
  position: absolute;
  left: 20px;
}

.apple__icon {
  width: 25px;
  height: 25px;
  position: absolute;
  left: 18px;
}

.facebook__icon {
  width: 25px;
  height: 25px;
  position: absolute;
  left: 18px;
  background-color: white;
  border-radius: 100px;
}

.sign__separated-line {
  flex: 1;
  background-color: rgba(255, 255, 255, 0.33);
  width: 100%;
  height: 1px;
}

.sign__text {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.03em;
  color: #fff;
  text-align: center;
  margin-right: 10px;
  margin-left: 10px;
}

.sign__text:first-child {
  margin-top: 0;
}

.sign__text a {
  position: relative;
  color: #6164ff;
}

.sign__text a:hover {
  color: #6164ff;
  text-decoration: underline;
}

.sign__text--small {
  font-size: 13px;
  line-height: 21px;
  text-align: left;
  color: #bdbdbd;
}

.sign__text-forget {
  font-size: 12px;
  line-height: 15px;
  font-weight: 400;
  letter-spacing: 0.03em;
  color: rgba(255, 255, 255, 0.66);
}

.sign__text-forget a {
  color: rgba(255, 255, 255, 0.66);
}

@media (max-width: 576px) {
  .sign {
    position: fixed;
    top: 0px;
    background: #14142f;
    z-index: 600;
    height: 100vh;
    width: 100%;
  }

  .sign__main-title {
    margin-top: 50px;
    font-size: 24px;
  }

  .sign__text-forget {
    font-size: 14px;
    line-height: 34px;
    color: rgba(255, 255, 255, 0.66);
  }

  .sign__form-label {
    font-size: 14px;
  }

  .sign__input {
    font-size: 16px;
  }
}

@media (min-width: 768px) {

  .sign__form--contacts,
  .sign__form--profile,
  .sign__form--create {
    margin-top: 30px;
  }

  .sign__form--contacts .sign__btn,
  .sign__form--profile .sign__btn,
  .sign__form--create .sign__btn {
    margin: 10px 0 15px;
  }
}

@media (min-width: 992px) {
  .sign__form--profile .sign__btn {
    width: 160px;
  }
}

/*==============================
	Page 404
==============================*/
.page-404 {
  display: block;
  position: relative;
}

.page-404__wrap {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  padding: 25px 0 0;
}

.page-404__content {
  background-color: #14142f;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  padding: 20px;
  position: relative;
  width: 100%;
  max-width: 420px;
  border: 1px solid #887bff;
}

.page-404__title {
  position: relative;
  color: #fff;
  line-height: 100%;
  font-size: 100px;
  margin-bottom: 10px;
  font-weight: 500;
}

.page-404__text {
  text-align: center;
  display: block;
  width: 100%;
  color: #bdbdbd;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 30px;
}

.page-404__btn {
  width: 100%;
  height: 50px;
  border-radius: 12px;
  background-color: #6164ff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 14px;
  position: relative;
  z-index: 2;
  text-transform: uppercase;
  font-weight: 500;
  margin: 0 auto;
}

.page-404__btn:hover {
  color: #fff;
  background-color: #534f77;
}

@media (min-width: 576px) {
  .page-404__content {
    padding: 40px;
  }
}

/*==============================
	Modal
==============================*/
.modal {
  position: relative;
  display: block;
  background-color: #14142f;
  margin: 40px auto;
  width: 100%;
  max-width: 420px;
  padding: 20px;
  border-radius: 16px;
  overflow: hidden;
}

.modal .mfp-close {
  display: none;
}

.modal__title {
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  letter-spacing: 0.02em;
}

@media (min-width: 768px) {
  .modal {
    padding: 40px;
  }
}

/*==============================
	Create Event Card
==============================*/
.cr__evcard--title {
  margin-top: 35px;
  font-weight: 700;
  font-size: 32px;
  line-height: 34px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #ffffff;
}

.cr__evcard-subItem-category {
  background: rgba(255, 255, 255, 0.03);
  border-radius: 12px;
}

.cr__evcard-subItem {
  margin-top: 20px;
  padding: 30px 20px 40px 0;
}

.cr__evcard--subtitle {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 15px;
}

.cr__evcard--subtitle span {
  width: 8px;
  height: 15px;
  background: #6a4dfd;
  margin-right: 15px;
}

.cr__evcard-labelTitle {
  font-size: 14px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.05em;
  color: #fff;
  margin-bottom: 0px;
  margin-top: 20px;
}

.cr__evcard-labelDesc {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.05em;
  color: rgba(255, 255, 255, 0.66);
  margin-bottom: 0px;
  margin-top: 7px;
  max-width: 362px;
}

.cr__evcard-uploadImg {
  display: flex;
  width: 100%;
  flex-direction: column;
  position: relative;
}

.cr__evcard-upload-block {
  background: rgba(255, 255, 255, 0.03);
  border-radius: 12px;
}

.cr__evcard-uploadImg span {
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0.15em;
}

.cr__evcard-uploadImg input {
  position: absolute;
  left: -9999px;
  opacity: 0;
  z-index: 1;
}

.cr__evcard-uploadImg img {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}

.cr__evcard-50 {
  width: 49%;
}

.cr__evcard-70 {
  width: 69%;
}

.cr__evcard-30 {
  width: 29%;
}

.cr__evcard-100 {
  width: 100%;
  padding-left: 23px;
}

.cr__evcard-rowParent {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: 23px;
  flex-wrap: wrap;
}

.cr__evcard-price-symbol {
  position: absolute;
  color: white;
  right: 14px;
  top: 52px;
}

.cr__evcard-selectArrow {
  position: absolute;
  right: 15px;
  top: 60px;
  width: 12px;
}

.cr__signup-selectArrow {
  position: absolute;
  right: 15px;
  top: 30px;
  width: 12px;
}

option {
  background: #6a4dfd;
}

@media (max-width: 640px) {
  .cr__evcard-50 {
    width: 100%;
  }
}

/*==============================
	Create Email
==============================*/
.cr__email--title {
  margin-top: 35px;
  font-weight: 700;
  font-size: 40px;
  line-height: 44px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.02em;
  color: #ffffff;
  margin-bottom: 30px;
}

@media (max-width: 400px) {
  .cr__email--title {
    font-size: 30px;
  }
}

.cr__email--formContainer {
  max-width: 880px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 18px;
  padding: 40px;
}

@media (max-width: 700px) {
  .cr__email--formContainer {
    padding: 30px;
  }
}

.cr__email--subtitle {
  font-size: 12px;
  font-weight: 400;
  color: #fff;
  letter-spacing: 0.05em;
  line-height: 12px;
  margin-bottom: 0px;
}

.cr__email-50 {
  width: 100%;
}

.cr__email-50 ::placeholder {
  color: rgba(255, 255, 255, 0.33);
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.02em;
}

/*==============================
	Create Article
==============================*/
.cr__article--subtitle {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 0.15em;
}

.cr__email--propstitle {
  font-size: 12px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.66);
  line-height: 12px;
  letter-spacing: 0.05em;
}

/*==============================
	See All Users
==============================*/
.see__users--header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}

@media (max-width: 992px) {
  .see__users--header {
    flex-direction: column;
  }
}

.see__users--title {
  flex: 1;
  font-weight: 700;
  font-size: 40px;
  line-height: 44px;
  letter-spacing: 0.02em;
  color: #ffffff;
  margin-bottom: 0px;
}

@media (max-width: 550px) {
  .see__users--title {
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
  }
}

.custom-row {
  display: flex !important;
  background-color: #14142f !important;
  width: 100% !important;
  border: none !important;
  color: #fff !important;
  font-size: 14px !important;
  font-weight: 400;
  letter-spacing: 0.03em;
  text-indent: 20px !important;
}

.odd-row {
  background-color: #20203a !important;
}

.ag-cell-value {
  display: flex !important;
  align-items: center !important;
}

.ag-row-hover {
  opacity: 0.9 !important;
}

.ag-center-cols-viewport {
  background-color: #191935;
}

.ag-header-row {
  background-color: #14142f !important;
  width: 1224px !important;
  color: #fff !important;
  text-transform: uppercase !important;
  letter-spacing: 0.15em !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  text-indent: 20px !important;
}

.ag-theme-alpine .ag-root-wrapper {
  border: none !important;
}

.like-img {
  width: 20px !important;
  margin-top: 3px;
}

.MuiTooltip-tooltip {
  background: #0d0d21 !important;
}

.MuiTooltip-arrow {
  color: #0d0d21 !important;
}

.arrow_up_button {
  color: white;
  position: fixed;
  height: 36px;
  bottom: 100px;
  right: 20px;
  border: 1px solid;
  border-radius: 36px;
  background: white;
}

.collection__filter_block {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
}

@media (max-width: 576px) {
  .collection__filter_block {
    flex-direction: column-reverse;
    max-width: 290px;
    margin: 44px auto 0 auto;
  }
}

@media (max-width: 650px) {
  .collection__filter_block {
    justify-content: center;
    gap: 20px;
  }
}

.container__search {
  margin-bottom: 20px;
  display: flex;
}

@media (max-width: 800px) {
  .container__search {
    justify-content: center;
  }
}

.ag-cell {
  user-select: text;
  border: 0px solid !important;
}

.ag-header-viewport {
  background-color: #14142f !important;
}

.purple-mark {
  width: 8px;
  height: 15px;
  background: #6a4dfd;
  margin-right: 56px;
  margin-left: -64px;
}

.addon-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  margin: 0 0 20px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.33);
}

.text__addon-name {
  color: #fff;
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  margin-bottom: 0;
}

.text__addon-description {
  flex: 1;
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  resize: none;
  border: none;
  margin-bottom: 0;
  margin-right: 20px;
}

.text__addon-price {
  color: #fff;
  width: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  margin-bottom: 0;
}

.addon-container {
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.03);
  padding: 30px;
  margin-left: 23px;
}

.referral-container {
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.03);
  padding: 30px;
  margin: 20px 0 0 0;
  padding: 10px 20px;
}

.referral__btn_container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 25px;
}

.seat-container {
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.03);
  padding: 5px 30px 25px 30px;
  margin-left: 23px;
}

.heart_icon {
  fill: none;
}

.heart_icon:hover {
  fill: white;
}

.home__container {
  padding: 0;
  justify-content: center;
  align-items: center;
  max-width: 1224px;
  width: calc(100vw - 20px);
  margin: auto;
}

@media (max-width: 1247px) {
  .home__container {
    padding: 0 10px;
  }
}

.p__zero {
  padding: 0 !important;
}

.img__trush {
  cursor: pointer;
  margin: auto;
}

.grid-container {
  display: grid;
  width: fit-content;
  grid-template-columns: auto auto auto auto;
  column-gap: 24px;
}

@media (max-width: 1235px) {
  .grid-container {
    display: grid;
    grid-template-columns: auto auto auto;
  }
}

@media (max-width: 932px) {
  .grid-container {
    display: grid;
    grid-template-columns: auto auto;
  }
}

@media (max-width: 620px) {
  .grid-container {
    display: grid;
    grid-template-columns: auto;
  }
}

@media (max-width: 628px) {
  .addon-icon {
    padding: 4px;
    margin-bottom: 4px !important;
  }
}

.addon-icon {
  margin-bottom: 20px;
}

.MuiDialog-paper {
  margin: 0 !important;
}

.empty {
  width: 0;
  height: 0;
}

.activity__container {
  margin-top: 70px;
  margin-bottom: 70px;
  padding: 0px;
  z-index: 999;
}

.activity__row {
  margin: auto;
}

.empty__text {
  color: white;
  margin: auto;
  text-align: center;
}

.card__container {
  margin: 0;
  padding: 0;
}

@media (min-width: 450px) {
  .bottom_block {
    height: 200px;
  }

  .sign_bottom {
    height: 200px;
    width: 200px;
  }
}

.down__all_report {
  margin-bottom: 20px;
  width: fit-content;
}

.refresh__btn {
  width: 340px;
}

@media (max-width: 600px) {
  .refresh__btn {
    width: 120px;
    font-size: 14px !important;
  }

  .down__all_report {
    font-size: 14px !important;
  }
}

.download__csv {
  margin-top: 0;
  width: 200px;
}

@media (max-width: 992px) {
  .download__csv {
    margin-top: 20px;
  }
}

.report__table {
  height: 500px;
  width: 100%;
  border-radius: 18px;
  border: 1px solid rgba(255, 255, 255, 0.33);
  overflow: hidden;
}

.fontBold {
  font-weight: 700 !important;
}

.description__contain {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding: 30px 40px 40px 40px;
  margin-top: 60px;
}

@media (max-width: 650px) {
  .description__contain {
    padding: 27px 24px 40px 24px;
  }
}

.description__title {
  border-bottom: none;
  width: fit-content;
  padding-bottom: 3px;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: #ffffff;
  cursor: pointer;
}

.description__title_active {
  border-bottom: 2px solid #6a4dfd;
  width: fit-content;
  padding-bottom: 3px;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: #ffffff;
  cursor: pointer;
}

@media (max-width: 500px) {
  .description__title_active {
    font-size: 16px;
  }

  .description__title {
    font-size: 16px;
  }

  .details__terms {
    gap: 20px !important;
  }
}

@media (max-width: 432px) {
  .description__title_active {
    font-size: 12px;
  }

  .description__title {
    font-size: 12px;
  }
}

.description__text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.03em;
  color: rgba(255, 255, 255, 0.66);
}

.create__item-contain {
  margin-bottom: 70px;
  margin-top: 70px;
  max-width: 1224px;
  width: 100%;
  z-index: 1;
}

.sign__recaptcha {
  margin-top: 15px;
  margin-bottom: 15px;
  align-self: flex-start;
}

.addon_content {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

@media (max-width: 650px) {
  .addon_content {
    justify-content: center;
  }
}

.details__terms {
  display: flex;
  gap: 35px;
}

.order__summary {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  padding: 20px 25px;
}

.order__summary_title {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 21px;
}

.order__summary_quantity {
  margin: -10px 0 10px 0;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.66);
}

.order__summary_item {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.order__summary_text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.03em;
  color: #ffffff;
  margin-bottom: 13px;
}

.divide {
  background: rgba(255, 255, 255, 0.33);
  height: 1px;
  width: 100%;
  margin-top: 3px;
  margin-bottom: 14px;
}

.mb-0 {
  margin-bottom: 0;
}

.terms__check {
  margin-top: 27px;
  margin-bottom: 23px;
}

.terms__text {
  font-style: normal;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 20px;
  letter-spacing: 0.03em;
  color: #ffffff !important;
}

.terms_and_conditions {
  color: #6a4dfd !important;
  text-decoration: underline !important;
}

.btn__disable {
  opacity: 0.3;
}

.left__arrow_btn {
  position: absolute;
  left: 20px;
  z-index: 100;
  fill: white;
  width: 25px;
  display: none;
  justify-content: center;
  align-items: center;
}

.right__arrow_btn {
  position: absolute;
  right: 20px;
  z-index: 100;
  fill: white;
  width: 25px;
  display: none;
  justify-content: center;
  align-items: center;
}

@media (max-width: 450px) {
  .left__arrow_btn {
    display: flex;
  }

  .right__arrow_btn {
    display: flex;
  }
}

.home__link {
  color: #fff;
  font-size: 30px;
  position: absolute;
  right: 30px;
  top: 30px;
  z-index: 666;
}

.profile__tab_title {
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.mt_50 {
  margin-top: 50px;
}

.profile__section {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 20px;
}

@media (max-width: 450px) {
  .profile__section {
    flex-direction: column;
  }
}

.profile__upload_text {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.15em;
}

.profile__description {
  height: 90px;
  resize: none;
  padding: 10px 20px;
}

.fixed__period {
  margin-bottom: 40px;
  padding-top: 0;
}

.download__container {
  display: flex;
  justify-content: flex-end;
}

.search__container {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  gap: 10px;
}

@media (max-width: 600px) {
  .search__container {
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }
}

.filter__download_container {
  display: flex;
  gap: 20px;
  position: relative;
}

.profile__setting_container {
  margin-bottom: 350px;
  margin-top: 20px;
  width: 100%;
  max-width: 1224px;
  z-index: 999;
}

@media (max-width: 450px) {
  .profile__setting_container {
    margin-bottom: 150px;
    margin-top: 0;
  }

  .royalty__container {
    flex-direction: column !important;
  }

  .profile__container {
    padding: 0px 25px !important;
  }
}

.back_arrow {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #14142f;
  width: 28px;
  height: 28px;
  left: 24px;
  top: 20px;
  border-radius: 14px;
}

.royalty__container {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.create__user_container {
  margin-bottom: 100px;
  margin-top: 70px;
  z-index: 999;
}

.create__user_btn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 25px;
}

.users_container {
  margin-bottom: 70px;
  margin-top: 70px;
  padding: 0px;
  z-index: 999;
}

@media (max-width: 700px) {
  .users_container {
    padding: 20px;
  }
}

.users_table {
  height: 700px;
  width: 100%;
  border-radius: 18px;
  border: 1px solid rgba(255, 255, 255, 0.33);
  overflow: hidden;
}

.create__article_container {
  margin-bottom: 100px;
  margin-top: 0;
  z-index: 999;
}

.create__post_btn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 25px;
}

@media (max-width: 900px) {
  .create__article_container {
    padding: 0 10px;
  }

  .post__btn {
    margin: auto !important;
  }
}

.collection__btn {
  width: 300px;
  margin: 15px 0;
}

.collection__btn_container {
  padding: 20px 0px;
  margin: 0;
  display: flex;
  gap: 20px;
}

@media (max-width: 628px) {
  .collection__btn_container {
    margin: auto;
    flex-direction: column;
  }
}

.ag-header-row-column {
  overflow: visible !important;
}

@media (min-width: 450px) {
  .owl-stage-outer {
    padding: 0 0 0 4px;
    width: 101%;
  }
}

.body__container {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.back__logo {
  position: absolute;
  top: 0;
}

.colum_start {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.flex__column_auto {
  flex-direction: column;
  height: auto;
}

.password__container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.sign__separated_container {
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: 35px;
  margin-bottom: 10px;
}

@media (max-width: 450px) {
  .sign__separated_container {
    margin-top: 20px;
  }
}

.img_eye {
  position: absolute;
  right: 15px;
  top: 38px;
  cursor: pointer;
}

.signup__img_eye {
  position: absolute;
  right: 15px;
  top: 35px;
  cursor: pointer;
}

.create__email_btn {
  margin-right: 0;
  width: 300px;
}

.create__item {
  width: 49%;
}

.create__item_container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media (max-width: 500px) {
  .create__item {
    width: 100%;
  }
}

@media (max-width: 860px) {
  .create__email_btn {
    margin: 15px auto;
  }
}

.post__btn {
  max-width: 340px;
  margin-right: 0px;
}

.check__ticket_btn {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 10px;
}

.restore__description {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.03em;
  color: rgba(255, 255, 255, 0.66);
  text-align: center;
  max-width: 320px;
}

.terms__container {
  margin-top: 70px;
  margin-bottom: 70px;
}

.camera__text {
  margin: 0 0 0 10px;
}

.selfie__start_container {
  width: 450px;
  max-width: 98%;
  text-align: center;
  color: white;
  border-radius: 10px;
  margin: 40px auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.map_container {
  aspect-ratio: 7/8;
  text-align: center;
  color: white;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  background: #14142f;
  position: relative;
  border-radius: 0 0 10px 10px;
}

.ricky__map_container {
  aspect-ratio: 8/6;
  text-align: center;
  color: white;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  background: #14142f;
  position: relative;
  border-radius: 0 0 10px 10px;
}

.ritz__map_container {
  aspect-ratio: 8/6;
  text-align: center;
  color: white;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  background: #14142f;
  position: relative;
  border-radius: 0 0 10px 10px;
}

.miami__map_container {
  aspect-ratio: 6/6;
  text-align: center;
  color: white;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  background: #14142f;
  position: relative;
  border-radius: 0 0 10px 10px;
}

.civic__map_container {
  aspect-ratio: 8/6;
  text-align: center;
  color: white;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  background: #14142f;
  position: relative;
  border-radius: 0 0 10px 10px;
}

.ahoy__map_container {
  aspect-ratio: 7/8;
  text-align: center;
  color: white;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  background: #14142f;
  position: relative;
  border-radius: 0 0 10px 10px;
}

.copernicus__map_container {
  aspect-ratio: 8/6;
  text-align: center;
  color: white;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  background: #14142f;
  position: relative;
  border-radius: 0 0 10px 10px;
}

.payment__success_container {
  width: 490px;
  max-width: 98%;
  text-align: center;
  background-color: #14142f;
  color: white;
  padding: 57px 41px 41px 41px;
  border-radius: 10px;
  border: 1px solid #534f77;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

@media (max-width: 560px) {
  .payment__success_container {
    padding: 20px 10px;
  }
}

.selfie__container {
  background-size: cover !important;
  background-attachment: scroll;
  width: 100%;
  position: relative;
  z-index: 10;
}

.selfie__back {
  border: transparent 2px solid;
  width: 19%;
}

.selfie__back:hover {
  border: white 2px solid;
  cursor: pointer;
  box-sizing: border-box;
}

.selfie__btn {
  margin: 10px 0 0 0;
  padding: 10px 22px;
  font-size: 16px;
  width: 49%;
  border: 1px solid rgba(255, 255, 255, 0.33);
  border-radius: 4px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.15em;
  color: #ffffff;
}

.selfie__title {
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #ffffff;
  margin: 0 auto auto auto;
  max-width: 273px;
}

.selfie__info {
  text-align: center;
  letter-spacing: 0.03em;
  margin: 10px auto 26px auto;
  max-width: 304px;
}

.selfie__select {
  margin: 5px 0 25px 0;
  width: 100%;
  background: radial-gradient(50% 50% at 50% 0%,
      #2e266d 0%,
      rgba(255, 255, 255, 0.05) 100%);
  border-radius: 8px;
  padding: 18px 18px 26px 18px;
}

.camera__img {
  width: 22px;
}

.photo {
  width: 640px;
  max-width: 98%;
  aspect-ratio: 1/1;
}

.seat__map {
  width: 640px;
  max-width: 98%;
  aspect-ratio: 1/1;
}

.seat__map_container {
  text-align: center;
  padding: 20px 30px;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.seat__map_title {
  font-size: 24px;
}

.seat__number {
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  width: 24px;
  height: 24px;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.seat__selected_number {
  background: #00f043;
  border: 1px solid #00f043;
  border-radius: 4px;
  color: black;
  width: 24px;
  height: 24px;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.non__seat {
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.2);
  width: 24px;
  height: 24px;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.non__seat_close {
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  color: rgba(255, 255, 255, 0.2);
  width: 24px;
  height: 24px;
  font-size: 13px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.seat__number:hover {
  border-color: white;
}

.seat {
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.blank__seat {
  width: 30px;
  height: 30px;
}

.seat__row_number {
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.seat__col_number {
  width: 30px;
}

.seating__area_txt {
  background: rgba(32, 32, 57, 255);
  margin-bottom: 0;
  padding: 15px 0;
  color: white;
  text-align: center;
  border-radius: 10px 10px 0 0;
}

.select__seats_txt {
  background: rgba(32, 32, 57, 255);
  margin-bottom: 0;
  color: white;
  text-align: center;
  margin-left: -10%;
}

.seating__bottom_txt {
  background: rgba(32, 32, 57, 255);
  margin-bottom: 0;
  padding: 10px 0;
  color: rgba(255, 255, 255, 0.66);
  text-align: center;
  padding: 24px 0;
}

.select__seat {
  background-color: #14142f;
  color: white;
  border-radius: 10px;
  border: 1px solid #534f77;
  width: fit-content;
  min-width: 400px;
  margin: 10px;
}

.nonseat__popup {
  background-color: #14142f;
  color: white;
  border-radius: 10px;
  border: 1px solid #534f77;
  width: fit-content;
  min-width: 400px;
  margin: 10px;
}

@media (max-width: 450px) {
  .nonseat__popup {
    min-width: 340px;
  }
}

.back_text {
  margin-left: 14px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  align-items: center;
  letter-spacing: 0.15em;

  color: #ffffff;
}

.btn_close {
  width: 25px;
  height: 25px;
  padding-bottom: 2px;
  border-radius: 3px;
  cursor: pointer;
}

.area__text {
  color: #ffffff;
  font-size: 20px;
}

.tax__block {
  display: flex;
  justify-content: center;
  align-items: baseline;
  gap: 10px;
}

.seat__buy_btn {
  width: 100%;
  height: 44px;
  left: 440px;
  top: 835px;
  background: #6a4dfd;
  border-radius: 4px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.15em;
  color: #ffffff;
  max-width: 240px;
}

.seat__buy_btn:hover {
  filter: drop-shadow(0px 0px 8px #6a4dfd);
}

.seat_select__top {
  width: 100%;
  position: relative;
  padding: 15px 30px;
  background: #20203a;
  display: flex;
  justify-content: space-between;
  border-radius: 12px 12px 0 0;
  gap: 40px;
}

.seat_select__bottom {
  width: 100%;
  position: relative;
  padding: 15px 30px;
  background: #20203a;
  display: flex;
  justify-content: space-between;
  border-radius: 0 0 12px 12px;
  gap: 30px;
}

@media (max-width: 576px) {
  .seat_select__bottom {
    flex-direction: column;
    gap: 0;
  }

  .area__text {
    text-align: center;
  }
}

.seat_select__back {
  cursor: pointer;
}

.create__user_item {
  /* display: flex; */
  justify-content: space-between;
  gap: 20px;
}

@media (max-width: 800px) {
  .create__user_item {
    flex-direction: column;
  }
}

.ticket__total_price {
  display: flex;
  align-items: baseline;
  color: #fff;
  font-size: 32px;
  font-weight: 700;
  margin: 0;
  width: 240px;
  justify-content: flex-end;
}

.seats__buy_container {
  display: flex;
  justify-content: center;
}

.selected_area {
  background: #20203a;
  text-align: center;
}

@media (max-width: 576px) {
  .main {
    margin-top: 0;
  }
}

.discount_symbol {
  position: absolute;
  color: white;
  right: 14px;
  top: 35px;
}

.create__eventCard_collection {
  display: flex;
  align-items: center;
  margin: 10px 0 20px 0;
  gap: 13px;
  flex-wrap: wrap;
}

.App {
  min-height: 100vh;
}

.collection__banner {
  background-repeat: no-repeat;
  background-position: center;
  /* background-size: 100% 100% !important; */
}

.notification_block {
  top: -15px !important;
}

.noti__title {
  font-size: 16px !important;
  text-transform: none !important;
  margin-left: 0 !important;
  letter-spacing: 0.1em !important;
}

.category__select_btns {
  display: flex;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;
}

.category__select_btns button {
  display: flex;
  align-items: center;
  text-align: center;
  margin-bottom: 0px;
  /* height: 34px; */
  padding: 8px 15px;
  border-radius: 22px;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.02em;
  color: #fff;
  z-index: 10;
}

@media (max-width: 576px) {
  .category__select_btns button {
    padding: 6px 10px;
    font-size: 12px;
  }
}

@media (max-width: 376px) {
  .category__select_btns button {
    padding: 5px 10px;
    font-size: 11px;
  }
}

@media (max-width: 350px) {
  .category__select_btns button {
    padding: 5px 10px;
    font-size: 10px;
  }
}

@media (max-width: 768px) {
  .wallet__container {
    margin: 0 !important;
  }
}

.wallet__container {
  margin-top: 70px;
  margin-bottom: 70px;
  padding: 5px;
  z-index: 999;
  display: flex;
  gap: 26px;
}

@media (max-width: 450px) {
  .wallet__container {
    margin-top: 0;
  }
}

.transaction_table {
  width: 100%;
  min-width: 780px;
}

@media (max-width: 489px) {
  .transaction_table {
    display: none !important;
  }
}

.transaction_table th {
  color: rgba(255, 255, 255, 0.75);
  font-size: 12px;
  font-weight: 400;
  height: 48px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.transaction_table td {
  color: white;
  font-size: 12px;
  font-weight: 400;
  height: 48px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.transaction__last_col {
  text-align: right !important;
}

.transaction_row:hover {
  background: rgba(255, 255, 255, 0.05);
}

.copied_txt {
  position: absolute;
  color: #6a4dfd;
  font-size: 14px;
  font-weight: 700;
  right: 0;
  top: -25px;
}

.top__header_search {
  margin-right: 40px;
  width: 345px;
}

@media (max-width: 1100px) {
  .top__header_search {
    margin-right: 30px;
    max-width: 250px !important;
  }
}

@media (max-width: 1000px) {
  .top__header_search {
    margin-right: 20px;
  }
}

@media (max-width: 840px) {
  .top__header_search {
    width: 170px;
  }
}

@media (max-width: 769px) {
  .top__header_search {
    display: none;
  }
}

.address__input {
  margin-top: 0;
  font-size: 14px;
}

.password__input {
  margin-bottom: 28px;
}

.owl-stage-outer {
  width: 100%;
}

.about__follow-icons {
  display: flex;
  justify-content: space-between;
  width: 165px;
  margin-top: 12px;
}

.about__follow-icons a {
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.06);
  border-radius: 4px;
}

.background__logo {
  width: 1058px;
}

@media (max-width: 1072px) {
  .background__logo {
    width: 450px;
  }
}

@media (max-width: 450px) {
  .background__logo {
    width: 100%;
  }
}

@media (max-width: 880px) {
  .message__container {
    padding: 0 10px;
  }
}

.text-error {
  color: #ffd33d;
  text-transform: none !important;
  font-weight: normal !important;
  letter-spacing: 0 !important;
  margin-top: 3px;
}

.main__author {
  background-position-x: center;
  background-position-y: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.card__delete_btn {
  position: absolute !important;
  top: 18px;
  left: 35%;
  z-index: 10;
}

input:disabled {
  background: transparent;
  border-color: white;
}

textarea:disabled {
  background: transparent;
  border-color: white;
}

textarea {
  overflow: hidden;
}

.countdown__main {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 13px;
  letter-spacing: 0.1em;
  font-weight: 400;
  padding: 5px 11px;
  width: fit-content;
  background: rgba(217, 217, 217, 0.1);
  border-radius: 40px;
  height: 32px;
}

.countdown__main-sm {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  border: 1px solid #6a4dfd;
  color: #fff;
  font-size: 10px;
  letter-spacing: 0.15em;
  font-weight: 400;
  padding: 5px 12px;
  margin: 20px 0 0 0;
}

.addon-icon img {
  border-radius: 12px;
  overflow: hidden;
  cursor: pointer;
}

.addon-icon img:hover {
  opacity: 0.5;
}

.full__container {
  width: 100%;
}

.text-editor-wrapper {
  width: 100%;
  margin-top: 10px;
}

.text-editor-wrapper *::selection {
  background: #3390ff;
  color: white;
}

.text-editor-wrapper>div {
  overflow-y: auto;
}

.text-editor-wrapper-article {
  width: 100%;
  margin-top: 10px;
  color: white;
}

.text-editor-wrapper-article>div {
  overflow-y: auto;
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
  color: inherit !important;
}

.profile__block {
  width: 100%;
  height: 100%;
  margin-top: 0px;
  background: aliceblue;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 1s;
}

.profile__block svg {
  width: 80px;
  height: 80px;
  transition: all 1s;
}

.profile__block img {
  width: 140px;
  height: 140px;
  transition: all 1s;
  border-radius: 16px;
  object-fit: cover;
}

.profile__block input[type="file"] {
  opacity: 0;
  position: absolute;
  cursor: pointer;
  z-index: 99999;
  border-radius: 16px;
  width: 145px;
  height: 145px;
}

.profile__block:hover {
  transition: all 1s;
  box-shadow: 0px 0px 15px 2px #387289;
}