.container {
  width: 500px;
  background-color: #14142f;
  color: white;
  padding: 30px;
  border-radius: 10px;
  border: 1px solid #534f77;
  margin: auto;
  max-width: 80%;
  position: relative;
}
.add__btn {
  background-color: #6a4dfd;
  height: 44px;
  width: 100%;
  border-radius: 4px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  letter-spacing: 0.15em;
  font-size: 16px;
  position: relative;
  text-transform: uppercase;
  font-weight: 700;
  padding: 0 20px;
  margin-top: 15px;
}
.add__btn:hover {
  filter: drop-shadow(0 0 8px #6a4dfd);
}

@media (max-width: 576px) {
  .container {
    max-width: 90%;
  }
}

.btn_close {
  width: 25px;
  height: 25px;
  padding-bottom: 2px;
  border-radius: 3px;
  cursor: pointer;
  position: absolute;
  right: 25px;
  top: 25px;
}

.title {
  width: 100%;
  /* text-align: center; */
  position: relative;
  font-size: 20px;
  font-weight: bold;
  margin-top: 27px;
}

.wrapper {
  margin-top: 20px;
  font-size: 12px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.wrapper input {
  margin-top: 3px;
  margin-bottom: 5px;
  border: 1px solid rgba(255, 255, 255, 0.33);
  border-radius: 4px;
  height: 44px;
  font-size: 14px;
  width: 100%;
  padding: 0 10px;
  color: #fff;
  background: transparent;
  outline: none;
}

.wrapper textarea {
  margin-top: 3px;
  margin-bottom: 5px;
  border: 1px solid rgba(255, 255, 255, 0.33);
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
  padding: 10px;
  color: #fff;
  background: transparent;
  outline: none;
}

.wrapper textarea:hover {
  border: 1px solid #ffffff !important;
  filter: drop-shadow(0px 0px 8px #6a4dfd);
}

.wrapper textarea:focus {
  border: 1px solid #6a4dfd !important;
  filter: drop-shadow(0px 0px 8px #6a4dfd);
}

.wrapper input:hover {
  border: 1px solid #ffffff !important;
  filter: drop-shadow(0px 0px 8px #6a4dfd);
}

.wrapper input:focus {
  border: 1px solid #6a4dfd !important;
  filter: drop-shadow(0px 0px 8px #6a4dfd);
}

.wrapper select:focus {
  border: 1px solid #6a4dfd !important;
  filter: drop-shadow(0px 0px 8px #6a4dfd);
}

.wrapper select:hover {
  border: 1px solid #ffffff !important;
  filter: drop-shadow(0px 0px 8px #6a4dfd);
}

.input_wrapper_full {
  width: 100%;
  position: relative;
}

.input_wrapper_half {
  width: 49%;
}

.warning_text {
  color: red;
}

@media (max-width: 576px) {
  .container {
    padding: 20px 20px;
  }
}
