.main__nav_prev, .main__nav_next {
  display: none;
}
@media (min-width: 1200px) {
  .main__nav_prev {
    right: 35px;
    width: 45px;
    height: 45px;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    top: -52px;
    z-index: 2;
  }
  .main__nav_prev svg:hover circle {
    stroke-opacity: 1;
  }
  .main__nav_next {
    right: -5px;
    width: 45px;
    height: 45px;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    top: -52px;
    z-index: 2;
  }
  .main__nav_next svg {
    color: red;
  }
  .main__nav_next svg:hover circle {
    stroke-opacity: 1;
  }
}
