.btn_close {
  width: 20px;
  height: 25px;
  display: inline-block;
  padding-bottom: 2px;
  border-radius: 3px;
  vertical-align: top;
  cursor: pointer;
  position: absolute;
  top: 28px;
  right: 28px;
}
.addon_content {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.addon_icon {
  width: 32px;
  height: 32px;
}
.addon_name {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  margin-top: 19px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #ffffff;
}
.addon_description {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: rgba(255, 255, 255, 0.66);
  word-break: break-word;
}
.event_name {
  text-align: left;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 25px;
}
.addon__modal_contain {
  max-width: 935px;
  text-align: center;
  background-color: #14142f;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.33);
  border-radius: 12px;
  box-sizing: border-box;
  margin: auto;
  padding: 30px 68px;
  max-height: 100vh;
  overflow: auto;
  position: relative;
}
@media (max-width: 980px) {
  .addon__modal_contain {
    padding: 24px;
    width: min-content;
  }
}
@media (max-width: 650px) {
  .addon_block {
    width: 274px !important;
  }
}
.addon_block {
  box-sizing: border-box;
  width: 380px;
  text-align: left;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding: 23px 22px 26px 22px;
  position: relative;
  overflow: hidden;
}
.addon__gold_effect {
  width: 284px;
  height: 284px;
  top: -142px;
  left: -142px;
  position: absolute;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(253, 193, 77, 0.25) 0%,
    rgba(253, 193, 77, 0) 100%
  );
}
.addon__blue_effect {
  width: 284px;
  height: 284px;
  top: -142px;
  left: -142px;
  position: absolute;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(106, 77, 253, 0.33) 0%,
    rgba(106, 77, 253, 0) 100%
  );
}
.addon__gray_effect {
  width: 284px;
  height: 284px;
  top: -142px;
  left: -142px;
  position: absolute;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(255, 255, 255, 0.15) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}
