.transaction_item {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  padding: 25px 20px;
  margin-bottom: 16px;
}
.item_title {
  font-size: 12px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.66);
  margin-bottom: 2px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
.item_value {
  font-size: 14px;
  font-weight: 400;
  color: white;
  margin-bottom: 0;
  overflow-wrap: break-word;
}
.hash_block {
  margin-bottom: 20px;
}
.date_block {
  margin-bottom: 22px;
}
.type_chain {
  display: flex;
  margin-bottom: 22px;
  gap: 40px;
}
@media (min-width: 490px) {
  .transaction_item {
    display: none;
  }
}
