.container {
  max-width: 80%;
  width: 1000px;
  overflow: auto;
  background-color: #14142f;
  color: white;
  padding: 20px 40px 25px;
  border-radius: 12px;
  border: 1px solid #534f77;
  margin: auto;
  position: relative;
}
.btn_close {
  width: 20px;
  height: 25px;
  display: inline-block;
  padding-bottom: 2px;
  border-radius: 3px;
  vertical-align: top;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 30px;
}
.event_name {
  text-align: left;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 25px;
}
@media (max-width: 576px) {
  .container {
    max-width: 95%;
    padding: 20px 20px 30px;
  }
}
