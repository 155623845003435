.report__container {
  margin-bottom: 50px;
  margin-top: 30px;
  padding: 0px;
  max-width: 1224px;
  width: 100%;
}

.back__container {
  display: flex;
}

.back__text {
  font-size: 16px;
  color: #FFFFFFA8;
  margin: 0 0 0 8px;
}

.title__container {
  display: flex;
  margin-top: 30px;
  align-items: center;
}

.title {
  font-size: 32px;
  line-height: 34px;
  font-weight: 600;
  color: #fff;
  margin: 0 0 0 16px;
}

.divide__line {
  color: #FFFFFFA8;
  font-weight: 400;
  font-size: 30px;
}

@media (max-width: 720px) {
  .title {
    font-size: 22px;
    line-height: 26px;
  }
  .divide__line {
    font-size: 20px;
  }
}

@media (max-width: 460px) {
  .title {
    font-size: 16px;
    line-height: 18px;
  }
  .divide__line {
    font-size: 14px;
  }
}

.btn__group {
  margin-top: 35px;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.calendar__btn {
  height: 44px;
  padding: 11px 14px 11px 14px;
  border-radius: 4px;
  border: 1px solid #FFFFFF54;
  display: flex;
  align-items: center;
}

.export__btn {
  height: 44px;
  padding: 11px 14px 11px 14px;
  border-radius: 4px;
  border: 1px solid #FFFFFF54;
  display: flex;
  align-items: center;
}

.btn__text {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  margin: 0 8px;
}

@media (max-width: 310px) {
  .calendar__btn {
    padding: 6px 10px;
  }
  
  .export__btn {
    padding: 6px 10px;
  }

  .btn__text {
    font-size: 14px;
  }
}

.top__block {
  margin-top: 16px;
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.block__item {
  width: calc(25% - 6px);
  min-width: 200px;
  height: 155px;
  background: #FFFFFF14;
  border-radius: 8px;
  padding: 23px 27px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (max-width: 858px) {
  .block__item {
    width: calc(33% - 5px);
  }
}

@media (max-width: 650px) {
  .block__item {
    width: calc(50% - 4px);
  }
}

@media (max-width: 445px) {
  .block__item {
    width: 100%;
  }
}

.item__title {
  font-size: 16px;
  margin: 0;
  color: #FFFFFFA8;
}

.item__content {
  font-size: 36px;
  font-weight: 500;
  margin: 0;
  color: #fff;
}

.chart__container {
  display: flex;
  margin-top: 16px;
  gap: 14px;
  flex-wrap: wrap;
}

.chart__block {
  width: 600px;
  border-radius: 8px;
  background: #FFFFFF08;
  padding: 20px;
}

@media (max-width: 910px) {
  .chart__block {
    width: 100%;
  }
}

.chart__title {
  font-size: 16px;
  color: #FFFFFFA8;
  margin-bottom: 20px;
}

.sold__block {
  background: #FFFFFF08;
  width: 300px;
  border-radius: 8px;
  padding: 23px 25px;
}

.sold__title {
  color: #FFFFFFA8;
  font-size: 16px;
  margin: 0 0 14px 0;
}

.item__block {
  height: 73px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #FFFFFF1A
}

.sold__number {
  font-size: 24px;
  font-weight: 500;
  color: #fff;
  margin: 0;
}

.ticket__name {
  color: #FFFFFFA8;
  font-size: 14px;
  margin: 0;
  font-weight: 400;
}

.percent__text {
  font-size: 16px;
  margin: 0;
  color: #FFFFFFA8;
}

.media__block {
  background: #FFFFFF08;
  min-width: 262px;
  flex: 1;
  border-radius: 8px;
  padding: 23px 25px;
}

.media__item {
  display: flex;
  align-items: center;
  gap: 16px;
}

.map__container {
  margin-top: 52px;
  border: 1px solid #FFFFFF26;
  border-radius: 8px;
  padding: 23px 27px 23px 27px;
}

.map__title {
  font-size: 16px;
  margin: 0;
  color: #FFFFFFA8;
}

.map__block {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.world__map {
  margin-top: 27px;
  width: 70%;
}

@media (max-width: 1092px) {
  .world__map {
    width: 100%;
    margin-left: 0;
  }
  .map__block {
    justify-content: center;
  }
}

.map__item {
  display: flex;
  justify-content: space-between;
  /* width: 306px; */
  min-width: 300px;
  height: 56px;
  align-items: center;
  border-bottom: 1px solid #FFFFFF33
}

@media (max-width: 400px) {
  .map__item {
    min-width: 220px;
  }
}

.flag__container {
  display: flex;
}

.flag {
  width: 24px;
  height: 24px;
}

.country__name {
  font-size: 14px;
  color: #fff;
  margin: 0 0 0 12px;
}

.map__percent {
  color: #FFFFFFA8;
  font-size: 14px;
  margin: 0;
}

.pie__container {
  margin-top: 26px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}

.system__container {
  width: calc(50% - 10px);
  border: 1px solid #FFFFFF26;
  border-radius: 8px;
  padding: 23px 27px;
}

@media (max-width: 760px) {
  .system__container {
    width: 100%;
  }
}

.pie__title {
  color: #FFFFFFA8;
  font-size: 16px;
  margin: 0 0 48px 0;
}

.tooltip {
  background: rgba(24, 24, 48, 1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 4px 8px;
  border-radius: 4px;
}

.tooltip__field {
  margin: 0;
  font-weight: 700;
}

.tooltip__value {
  margin: 0;
}

@media (max-width: 1240px) {
  .report__container {
    padding: 10px;
    margin-top: 0;
    margin-bottom: 20px;
  }
}

.date__picker {
  background: #101029;
  border-radius: 8px;
  border: 1px solid #FFFFFF1A;
  width: 276px;
  position: absolute;
  top: 50px;
  left: 0px;
  padding: 20px;
  z-index: 10;
}
.picker__title {
  color: #FFFFFFA8;
  font-size: 16px;
  margin-bottom: 16px;
  margin-top: 0;
}

.radio__container {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 14px;
  cursor: pointer;
  font-size: 16px;
  color: #fff;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.radio__container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.radio__checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 16px;
  width: 16px;
  /* background-color: #eee; */
  border: 1px solid #FFFFFF54;
  border-radius: 50%;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radio__checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio__container input:checked ~ .radio__checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio__container .radio__checkmark:after {
 	top: 3px;
	left: 3px;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: #6A4DFD;
}

.custom__container {
  background: #FFFFFF0D;
  border-radius: 8px;
  padding: 14px 14px 5px 19px;
}

.from__text {
  color: #FFFFFFA8;
  font-size: 14px;
  margin: 0;
}