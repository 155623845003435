.report_data {
  width: 100%;
  margin-bottom: 60px;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.report_item {
  font-size: 23px;
}

@media (max-width: 600px) {
  .report_item {
    gap: 20px;
    font-size: 16px;
    text-align: center;
  }
}

.report_item_number {
  font-size: 30px;
}

.period_wrapper {
  display: flex;
  gap: 10px;
  align-items: center;
}

.period_symbol {
  font-size: 20px;
  color: white;
  transform: translate(0, 5px);
}

.down__all_contain {
  display: flex;
}
@media (max-width: 700px) {
  .down__all_contain {
    justify-content: center;
  }
}

.report__container {
  margin-bottom: 70px;
  margin-top: 30px;
  padding: 0px;
  z-index: 999;
  max-width: 1224px;
  width: 100%;
}

@media (max-width: 1240px) {
  .report__container {
    padding: 20px;
  }
}
