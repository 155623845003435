.container {
  width: 563px;
  background-color: #14142f;
  color: white;
  padding: 25px 20px;
  border-radius: 10px;
  border: 1px solid #534f77;
  margin: auto;
  max-width: 80%;
  max-height: 100vh;
  overflow: auto;
}

@media (max-width: 576px) {
  .container {
    max-width: 95%;
  }
}

.item {
  padding-bottom: 15px;
}

.description {
  color: #fff;
  margin-bottom: 10px;
}

.url {
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 0;
}
