.header__nav_link {
    display: flex;
    font-size: 14px;
    color: #bdbdbd;
    align-items: center;
}

.bell__img {
    cursor: pointer;
}

.bell__red {
    position: absolute;
    right: 0;
    top: -3px;
    width: 8px;
}

.container {
    position: relative;
}

.content {
    position: absolute;
    right: 0;
    top: 43px;
    background-color: #0e0e24;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.33);
    width: 270px;
    min-height: 100px;
    max-height: 200px;
    padding: 15px;
    overflow: auto;
}

.itemContent {
    margin-bottom: 7px;
}

.allText {
    color: #fff;
    margin: 0;
}

.description {
    color: rgba(255, 255, 255, 0.6);
    border-bottom: 1px solid rgba(255, 255, 255, 0.6);
}

.description > p {
    background-color: transparent !important;
    margin-bottom: 7px;
}

.description > p > span {
    background-color: transparent !important;
}