.container {
  width: 500px;
  background-color: #14142f;
  color: white;
  border-radius: 10px;
  border: 1px solid #534f77;
  margin: auto;
  max-width: 80%;
  padding: 30px;
}
.add__btn {
  background-color: #6a4dfd;
  height: 44px;
  width: 100%;
  border-radius: 4px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  letter-spacing: 0.15em;
  font-size: 16px;
  position: relative;
  text-transform: uppercase;
  font-weight: 700;
  padding: 0 20px;
  margin-top: 15px;
}
.add__btn:hover {
  filter: drop-shadow(0 0 8px #6a4dfd);
}

.guideText {
  text-align: center;
}

.qrcodeContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.qrcodeBlock {
  background: #fff;
}

.wrapper input {
  margin-top: 3px;
  margin-bottom: 5px;
  border: 1px solid rgba(255, 255, 255, 0.33);
  border-radius: 4px;
  height: 44px;
  font-size: 14px;
  width: 100%;
  padding: 0 10px;
  color: #fff;
  background: transparent;
  outline: none;
}

.wrapper input:hover {
  border: 1px solid #ffffff !important;
  filter: drop-shadow(0px 0px 8px #6a4dfd);
}

.wrapper input:focus {
  border: 1px solid #6a4dfd !important;
  filter: drop-shadow(0px 0px 8px #6a4dfd);
}

@media (max-width: 576px) {
  .container {
    max-width: 90%;
  }
}
