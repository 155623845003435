.paginator {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
  margin-top: 40px;
  max-width: 1224px;
}
.paginator__pages {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.66);
  font-weight: 400;
}
.paginator__list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}
.paginator__list li {
  margin-right: 15px;
}
.paginator__list li:last-child {
  margin-right: 0;
}
.active__button {
  cursor: default;
  font-weight: 700;
  border: 1px solid #6a4dfd;
  border-radius: 4px;
}
.paginator__list button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  color: #fff !important;
  font-weight: 400;
}
.paginator__list button svg {
  width: 26px;
  height: auto;
  fill: #fff;
  transition: fill 0.3s ease;
}
.paginator__list button:hover {
  color: #6164ff;
}
.paginator__list button:hover svg {
  fill: #6164ff;
}
.arrow__btn {
  display: none;
}

@media (max-width: 600px) {
  .paginator {
    justify-content: center;
  }
  .paginator__list {
    font-size: 16px;
  }
  .paginator__pages {
    display: none;
  }
  .arrow__svg {
    display: none;
  }
  .arrow__btn {
    display: block;
  }
  .number__btn {
    padding: 21px;
  }
}
