.container {
  max-width: 80%;
  width: 600px;
  background-color: #14142f;
  color: white;
  overflow: auto;
  padding: 20px 40px 25px;
  border-radius: 12px;
  border: 1px solid #534f77;
  margin: auto;
  position: relative;
}
.btn_close {
  position: absolute;
  top: 20px;
  right: 35px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.btn_crypto {
  margin-top: 15px;
  width: 100%;
  border-radius: 4px;
  height: 44px;
  border: 1px solid rgba(255, 255, 255, 0.33);
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn_crypto:hover {
  /* margin-top: 13px; */
  /* margin-bottom: 2px; */
  border: 1px solid white;
}
.btn_crypto span {
  color: white;
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.15em;
}
.text_or {
  font-size: 12px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.66);
  text-transform: uppercase;
  letter-spacing: 0.15em;
  text-align: center;
  margin: 0;
  margin-top: 30px;
}
.bitget_img {
 border-radius: 30px;
 width: 30px;
 height: 30px;
}
.discount_code {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  padding: 20px 25px;
  margin-top: 12px;
}
.discount_text {
  font-size: 14px;
  font-weight: 700;
  line-height: 17.86px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
}
.discount_content {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
}
.input_container {
  flex: 1;
  min-width: 150px;
}
.discount_input {
  width: 100%;
  height: 40px;
  border: 1px solid #FFFFFF54;
  border-radius: 4px;
  color: #fff;
  background: transparent;
  padding: 13px 15px;
}
.btn_apply {
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  height: 40px;
  padding: 8px 20px;
  border-radius: 4px;
  border: 1px solid #fff;
  letter-spacing: 0.15em;
}
.wrong_code {
  color: #FF3535;
  font-size: 12px;
  margin: 5px 0 0 0;
}
.code_text {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.66);
}
.discount_fiat {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.03em;
  color: #24FF00;
  margin-bottom: 13px;
}
.discount__success {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  padding: 20px 32px;
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
}
.discount__success_right {
  display: flex;
  gap: 12px;
}
@media (max-width: 576px) {
  .container {
    max-width: 95%;
    width: 500px;
    padding: 35px 20px 30px;
  }
  .btn_close {
    top: 30px;
    right: 15px;
  }
}
