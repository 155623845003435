.collection__contain_block {
  margin-bottom: 70px;
  margin-top: 20px;
  width: 1224px;
  z-index: 999;
}
.collection_description {
  margin-top: 10px;
  color: white;
}
.cr__collection_selectArrow {
  position: absolute;
  right: 15px;
  top: 30px;
  width: 12px;
}
@media (max-width: 1224px) {
  .collection__contain_block {
    width: 100%;
  }
}
.collection_input__upload:hover {
  border-color: white;
}
.collection_input__upload {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  margin-bottom: 0px;
  margin-top: 10px;
  border: 1px solid rgba(255, 255, 255, 0.33);
  border-radius: 4px;
  height: 44px;
  font-size: 16px;
  width: 100%;
  padding: 0 20px;
  color: #fff;
  background: transparent;
  outline: none;
}
.generate_btn {
  width: 100%;
  border-radius: 4px;
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.33);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
  margin: 15px auto;
  letter-spacing: 0.15em;
  padding: 10px 15px;
}
.title {
  margin-top: 35px;
  font-weight: 700;
  font-size: 40px;
  line-height: 44px;
  width: 100%;
  letter-spacing: 0.02em;
  color: #ffffff;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.input {
  margin-top: 10px;
  border: 1px solid rgba(255, 255, 255, 0.33);
  border-radius: 4px;
  height: 44px;
  font-size: 16px;
  width: 100%;
  padding: 0 20px;
  color: #fff;
  background: transparent;
  outline: none;
}
.input:focus {
  border: 2px solid #6a4dfd !important;
  filter: drop-shadow(0px 0px 8px #6a4dfd);
}
.input:hover {
  border: 1px solid #ffffff;
  filter: drop-shadow(0px 0px 8px #6a4dfd);
}
@media (max-width: 576px) {
  .input {
    font-size: 16px;
  }
}
.error_text {
  color: #ffd33d;
  text-transform: none;
  font-weight: normal;
  letter-spacing: 0;
  margin-top: 3px;
}
.cr__collection_container {
  margin: 70px auto 220px auto;
  z-index: 999;
}
@media (max-width: 530px) {
  .cr__collection_container {
    padding: 20px;
  }
}
.cr__collection_formContainer {
  width: 680px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 18px;
  padding: 57px 64px;
}
@media (max-width: 700px) {
  .cr__collection_formContainer {
    width: 100%;
    padding: 30px 30px;
  }
}
.purple_mark {
  width: 8px;
  height: 15px;
  background: #6a4dfd;
  margin: 4px 56px 0 -64px;
}
@media (max-width: 700px) {
  .purple_mark {
    margin: 4px 22px 0 -30px;
  }
}
.cr__collection_subtitle {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 0.15em;
}
.coll__grid_container {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}
.main__banner {
  overflow: hidden;
  position: relative;
  display: flex;
}
.main__banner > img {
  border-radius: 12px 12px 0px 0px;
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center;
}
.default__banner {
  width: 100%;
  max-height: 300px !important;
}
.empty__block {
  height: 400px;
}
@media (max-width: 1256px) {
  .coll__grid_container {
    display: grid;
    grid-template-columns: auto auto auto;
  }
  .main__banner {
    padding: 0 16px;
  }
}
@media (max-width: 944px) {
  .coll__grid_container {
    display: grid;
    grid-template-columns: auto auto;
  }
}
@media (max-width: 628px) {
  .coll__grid_container {
    display: grid;
    grid-template-columns: auto;
  }
}