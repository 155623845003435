.title {
  margin-top: 35px;
  font-weight: 700;
  font-size: 40px;
  line-height: 44px;
  width: 100%;
  letter-spacing: 0.02em;
  color: #ffffff;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.code_txt {
  color: #ffffff;
  font-size: 20px;
  margin: 20px auto 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.generate_btn {
  width: 100%;
  border-radius: 4px;
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.33);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
  margin: 15px auto;
  letter-spacing: 0.15em;
  padding: 10px 15px;
}

.generate_btn:hover {
  border: 1px solid rgba(255, 255, 255);
}

.input {
  margin-top: 10px;
  border: 1px solid rgba(255, 255, 255, 0.33);
  border-radius: 4px;
  height: 44px;
  font-size: 16px;
  width: 100%;
  padding: 0 20px;
  color: #fff;
  background: transparent;
  outline: none;
}

.input:focus {
  border: 2px solid #6a4dfd !important;
  filter: drop-shadow(0px 0px 8px #6a4dfd);
}

.input:hover {
  border: 1px solid #ffffff;
  filter: drop-shadow(0px 0px 8px #6a4dfd);
}

.container {
  padding: 10px;
}

.promo_form {
  margin: auto;
  max-width: 300px;
}

.error_text {
  color: #ffd33d;
  text-transform: none;
  font-weight: normal;
  letter-spacing: 0;
  margin-top: 3px;
}

@media (max-width: 576px) {
  .input {
    font-size: 16px;
  }
}
