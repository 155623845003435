.article {
  display: block;
  position: relative;
  width: 100%;
  margin: 30px 0px;
  max-width: 800px;
  z-index: 999;
}

.article__content {
  position: relative;
}

.article__content img {
  display: block;
  width: 100% !important;
  height: auto !important;
  width: fit-content;
  max-width: 100%;
  margin-bottom: 15px;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 8px 8px 8px 8px;
}

.article__content iframe {
  display: block;
  width: 100%;
  height: 240px;
  margin-bottom: 20px;
  border-radius: 16px;
  border: none;
}

.article__content h1,
.article__content h2,
.article__content h3,
.article__content h4,
.article__content h5,
.article__content h6 {
  font-weight: 700;
  color: #fff;
  margin-bottom: 15px;
  line-height: 140%;
  letter-spacing: 0.02em;
}

.article__content h1:last-child,
.article__content h2:last-child,
.article__content h3:last-child,
.article__content h4:last-child,
.article__content h5:last-child,
.article__content h6:last-child {
  margin-bottom: 0;
}

.article__content h1 {
  font-size: 40px;
  line-height: 41px;
}

.article__content h2 {
  font-size: 28px;
}

.article__content h3 {
  font-size: 24px;
}

.article__content h4 {
  font-size: 22px;
}

.article__content h5 {
  font-size: 18px;
}

.article__content h6 {
  font-size: 16px;
}

.article__content p {
  display: block;
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.66);
  letter-spacing: 0.02em;
  margin-bottom: 20px;
  word-break: break-word;
}

.article__content p b {
  font-weight: 600;
}

.article__content p a {
  color: #6164ff;
  text-decoration: underline;
}

.article__content p a:hover,
.article__content p a:focus {
  color: #6164ff;
  text-decoration: none;
}

.article__content p:last-child {
  margin-bottom: 0;
}

.article__content blockquote {
  display: block;
  position: relative;
  padding: 0 0 0 20px;
  font-size: 16px;
  line-height: 28px;
  color: #fff;
  font-weight: 400;
  margin-bottom: 20px;
}

.article__content blockquote:before {
  content: "";
  position: absolute;
  display: block;
  top: 2px;
  bottom: 2px;
  left: 0;
  width: 3px;
  background-color: #6164ff;
  border-radius: 3px;
}

.article__content blockquote:last-child {
  margin-bottom: 0;
}

.article__content ul {
  margin-bottom: 20px;
  display: block;
}

.article__content ul li {
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
  color: #bdbdbd;
  padding-left: 20px;
  position: relative;
}

.article__content ol li {
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
  color: #bdbdbd;
  padding-left: 20px;
  position: relative;
}

.article__content ul:last-child {
  margin-bottom: 0;
}

.article__content_title {
  color: white;
  font-weight: bold !important;
  margin-bottom: 10px;
}

.edit__article {
  margin: 20px 0 40px 0;
  border: 1px solid rgba(255, 255, 255, 0.33);
  border-radius: 4px;
  height: 44px;
  font-size: 16px;
  width: 140px;
  text-align: center;
  padding: 10px 20px;
  color: #fff;
  background: transparent;
  outline: none;
}

.img_wrapper {
  position: relative;
  display: inline-block;
  margin-bottom: 60px;
  width: 100%;
}

.article__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 10px;
}

.top__article_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 10px;
}

.article__news_group {
  display: flex;
  flex-wrap: wrap;
}

.article__like_group {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  position: relative;
}

.article__category {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.66);
  font-size: 12px;
  font-weight: 500;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 13px;
  padding: 0 18px;
  margin-right: 20px;
}

.article__category:hover {
  color: #fff;
  background-color: #534f77;
}

.article__date {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.66);
  height: 28px;
}

.article__date svg {
  fill: rgba(255, 255, 255, 0.66);
  width: 16px;
  height: 16px;
  height: auto;
  margin-right: 5px;
}

.article__back_block {
  display: flex;
  margin-top: 50px;
  gap: 10px;
  align-items: center;
}

.article__img {
  width: 20px;
  margin-bottom: 1px;
}

.article__back_text {
  margin-bottom: 0;
  color: white;
  font-size: 14px;
}

.dot {
  color: rgba(255, 255, 255, 0.66);
  margin: 0 9px;
}

.article__message {
  display: flex;
  align-items: center;
}

.article__message img {
  width: 16px;
  height: 16px;
  margin: 0px 5px;
  background: none;
}

.article__message p {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.5px;
  color: rgba(255, 255, 255, 0.66);
}

.article__like {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.article__like img {
  width: 20px;
  height: 20px;
  margin: 0px 5px;
  background: none;
}

.article__like p {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.5px;
  color: rgba(255, 255, 255, 0.66);
}

.article__like:hover p {
  color: white;
}

.article__like:hover svg path {
  stroke-opacity: 1;
}

.share__btn {
  display: flex;
  align-items: center;
  cursor: pointer;
  background: #6A4DFD;
  border-radius: 16px;
  padding: 3px 12px;
}

.share__btn img {
  width: 20px;
  height: 20px;
  margin: 0px 5px;
  background: none;
}

.share__btn p {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.5px;
  color: rgba(255, 255, 255, 0.66);
  color: white;
}

.share__btn svg path {
  stroke-opacity: 1;
}

.dropdown__share {
  background: #08081B;
  border-radius: 8px;
  border: 1px solid #FFFFFF1A;
  width: 216px;
  position: absolute;
  top: 40px;
  right: 3px;
  padding: 24px;
}

.dropdown__share_bottom {
  background: #08081B;
  border-radius: 8px;
  border: 1px solid #FFFFFF1A;
  width: 216px;
  position: absolute;
  top: 40px;
  right: 3px;
  padding: 24px;
}

@media (max-width: 576px) {
  .article__content iframe {
    height: 280px;
  }

  .article__content h1 {
    font-size: 24px;
    line-height: 25px;
  }

  .img_wrapper {
    margin-bottom: 25px;
  }
}

@media (max-width: 820px) {
  .article {
    padding: 0px 10px;
    margin: 10px 0px;
  }
}

@media (max-width: 410px) {
  .top__article_container {
    flex-direction: column;
    align-items: flex-start;
  }
  .dropdown__share {
    left: 0;
  }
}

@media (max-width: 300px) {
  .dropdown__share_bottom {
    left: 0;
  }
}
