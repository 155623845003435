.offer_block {
  padding: 12px 34px 13px 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.05);
  margin-bottom: 12px;
  position: relative;
  gap: 10px;
  flex-wrap: wrap;
}
.offer_title {
  font-size: 16px;
  font-weight: 700;
  color: white;
  margin-bottom: 8px;
}
.offer_description {
  font-size: 14px;
  font-weight: 400;
  color: white;
  margin-bottom: 22px;
}
.offer_date {
  font-size: 12px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.66);
  margin-bottom: 0;
}
.offer_btn {
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.33);
  width: 148px;
  height: 44px;
  color: white;
}
.offer_btn:hover {
  border: 1px solid white;
}
.offer__ellipse_green {
  background: radial-gradient(50% 50% at 0% 0%, rgba(5, 255, 0, 0.33) 0%, rgba(5, 255, 0, 0) 210%);
  border-radius: 8px 0 0 0;
  width: 44px;
  height: 44px;
  position: absolute;
  top: 0;
  left: 0;
}
.offer__green_dot {
  background: #5CE561;
  width: 6px;
  height: 6px;
  border-radius: 6px;
  position: absolute;
  top: 20px;
  left: 20px;
}
@media (max-width: 550px) {
  .offer_btn {
    width: 100%;
  }
  .offer_description {
    margin-bottom: 14px;
  }
}
