.explorer__card_item {
  padding: 0;
  margin-right: 0;
  cursor: pointer;
}
.card {
  margin-top: 20px !important;
  margin-bottom: 10px;
  border-radius: 12px;
  height: 559px;
  width: 288px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background: rgba(255, 255, 255, 0.05) !important;
}
.card:hover {
  margin-top: 16px !important;
  box-shadow: 0px 0px 8px #6a4dfd;
}
.card_sold {
  color: white;
  border-radius: 20px;
  padding: 5px 15px;
  font-size: 14px;
  font-weight: bold;
  position: absolute;
  top: 245px;
  left: 15px;
  z-index: 9;
  background: red;
}
.card__cover {
  width: 100%;
  height: 288px;
  background-color: rgba(255, 255, 255, 0.07);
  border-radius: 12px 12px 0px 0px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.card__cover img {
  width: 100%;
  max-height: 288px;
}
.card__info_contain {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}
.card__mark {
  position: absolute;
  z-index: 100;
}
.card__likes_top {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 13.8px;
  right: 16px;
  width: 40px;
  height: 40px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.explorer__card_title {
  align-items: flex-start;
  margin-bottom: -10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  color: #fff;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-wrap: break-word;
  font-weight: 700;
  font-size: 16px;
  transition: color 0.5s ease;
  line-height: 20px;
}
.explorer__card_title a {
  margin-top: 18px;
  margin-bottom: 12px;
  min-height: 38px;
  white-space: normal;
  color: #fff;
}
.explorer__card_title a:hover {
  color: #6164ff;
}
.explorer__card_title:hover {
  color: #6164ff;
}
.text__location {
  display: flex;
  width: 100%;
  height: 75px;
  overflow: hidden;
  padding-top: 12px;
  padding-bottom: 10px;
  justify-content: space-between;
}
.text__location_item {
  width: 48%;
  line-height: 19px;
}
.text__location_key {
  font-size: 10px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.66);
  text-transform: uppercase;
  letter-spacing: 0.15em;
  margin: 0;
}
.text__location_val {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  text-transform: capitalize;
  letter-spacing: 0.02em;
}
.text__location_price {
  font-size: 24px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 0;
}
.text__card_key {
  font-size: 10px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.66);
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 1px;
}
.card__price {
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.card__price span:first-child {
  font-size: 14px;
  line-height: 100%;
  color: #bdbdbd;
}
.card__price span:last-child {
  font-size: 18px;
  line-height: 100%;
  color: #fff;
  margin-top: 10px;
}
.buy__btn_tile {
  flex: 1;
  width: 100%;
  height: 44px;
  border-radius: 4px;
  background-color: #6a4dfd;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 16px;
  z-index: 2;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.15em;
  margin-top: 0px;
  margin-bottom: 0px;
}
.buy__btn_tile:hover {
  filter: drop-shadow(0px 0px 8px #6a4dfd);
}
.count__text {
  color: white;
  font-size: 16px;
  margin: auto 0 auto 8px;
}
.card__explorer_info {
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  padding: 8px 0px 8px;
}
.empty__addons_contain {
  width: 100%;
  margin: 15px 0 15px 0;
  height: 32px;
}
.addons_text {
  margin-bottom: 0 !important;
  margin-left: 8px;
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #ffffff;
}
.addons_contain {
  width: 100%;
  margin: 15px 0 15px 0;
  background: linear-gradient(
    90.9deg,
    rgba(255, 255, 255, 0.15) 0%,
    rgba(255, 255, 255, 0.05) 101.02%
  );
  border-radius: 4px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 450px) {
  .card {
    margin: auto;
  }
}
@media (max-width: 320px) {
  .card {
    height: 100%;
    width: 100%;
  }
}
@media (max-width: 288px) {
  .card__cover {
    height: 100%;
  }
}
