.early__bird_home {
  padding: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  max-width: 1224px;
  width: 100%;
}
.big_hero {
  width: 100%;
  cursor: pointer;
}
.early__bird_img {
  width: 100%;
  border-radius: 12px 12px 0 0;
}
.early__bird_bottom {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 0 0 12px 12px;
  width: 100%;
  padding: 15px 0;
}
.big__hero_desk {
  display: block;
  width: 100%;
  border-radius: 12px 12px 0 0;
}
.big__hero_mobile {
  display: none;
  width: 100%;
  border-radius: 12px 12px 0 0;
}
.early__bird_text {
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.2em;
  color: #ffffff;
  text-shadow: 0px 0px 16px rgba(255, 255, 255, 0.66);
  margin-bottom: 0;
  text-transform: uppercase;
}
.early__bird_date {
  font-weight: 700;
  font-size: 16px;
}
.early__bird_item {
  width: 48%;
  cursor: pointer;
}
.main__sub__title {
  margin: 60px 0 0 0;
  font-size: 40px;
  font-weight: 700;
  color: #fff;
}
.feature {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 40px;
  flex-wrap: wrap;
}
.feature__icon {
  width: 76px;
  height: 76px;
}
.feature__title {
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  color: #fff;
  letter-spacing: 0.02em;
  margin-top: 20px;
  margin-bottom: 0px;
}
.feature__item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
  padding: 45px 65px 20px 35px;
  /* width: 25%; */
  width: 300px;
  height: 280px;
  background-image: url("https://backstage.global/img/started/deskBack.png");
  background-size: 100% 280px;
  background-repeat: no-repeat;
}
.feature__last_item {
  background-image: url("https://backstage.global/img/started/deskBack2.png") !important;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
  padding: 45px 35px 20px 35px;
  width: 25%;
  height: 280px;
  background-size: 100% 280px;
}
.feature__text {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: #bdbdbd;
  margin-bottom: 0;
  margin-top: 10px;
  letter-spacing: 0.02em;
}
.feature__title a {
  color: #6a4dfd;
  font-weight: 700;
  text-decoration: underline;
}
.home {
  margin-bottom: 210px;
}
.home__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 90px;
}
.home__title_backstage {
  font-size: 66px;
}
.home__title {
  z-index: 2;
  color: #fff;
  font-weight: 700;
  font-size: 64px;
  text-align: center;
  line-height: 64px;
}
.home__btns {
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
  margin-top: 10px;
}
.home__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 173px;
  height: 44px;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
  z-index: 2;
  text-transform: uppercase;
  font-weight: 700;
  margin-top: 5px;
  letter-spacing: 0.15em;
  background-color: #6a4dfd;
}
.home__btn:hover {
  filter: drop-shadow(0px 0px 8px #6a4dfd);
  margin-top: 3px;
  margin-bottom: 2px;
  color: #fff;
}
.home__btn_blank {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 173px;
  height: 44px;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
  z-index: 2;
  text-transform: uppercase;
  font-weight: 700;
  margin-right: 25px;
  margin-top: 5px;
  letter-spacing: 0.15em;
}
.home__btn_blank {
  margin-right: 0;
  border: solid 1px white;
}
.home__btn_blank:hover {
  filter: drop-shadow(0px 0px 6px #ffffff);
  margin-top: 3px;
  margin-bottom: 2px;
  color: #fff;
}
@media (max-width: 810px) {
  .home__title {
    font-size: 36px;
    line-height: 40px;
  }
  .home__title_backstage {
    font-size: 40px;
    line-height: 42px;
  }
}
@media (max-width: 576px) {
  .home {
    margin-bottom: 0px;
  }

  .home__title {
    font-size: 24px;
    line-height: 28px;
  }
  .home__title_backstage {
    font-size: 24px;
    line-height: 28px;
  }
  .home__text {
    font-size: 14px;
    line-height: 18px;
    padding-left: 50px;
    padding-right: 50px;
  }
  .home__btn {
    width: 124px;
    margin: 0;
  }
  .home__btn_blank {
    width: 124px;
    margin-top: 0;
  }
  .home__content {
    margin-top: 0px;
  }
}
@media (max-width: 1000px) {
  .feature {
    flex-direction: column;
    align-items: center;
  }
  .feature__title {
    text-align: center;
  }
  .feature__text {
    text-align: center;
  }
}

@media (max-width: 1250px) {
  .early__bird_home {
    padding: 0 10px;
  }
}
@media (max-width: 992px) {
  .early__bird_text {
    font-size: 10px;
  }
  .early__bird_date {
    font-size: 12px;
  }
}
@media (max-width: 768px) {
  .early__bird_item {
    width: 100%;
  }
  .early__bird_text {
    font-size: 13px;
  }
  .early__bird_date {
    font-size: 15px;
  }
}
@media (max-width: 576px) {
  .early__bird_home {
    padding: 0 16px;
  }
  .early__bird_text {
    font-size: 11px;
  }
  .early__bird_date {
    font-size: 13px;
  }
  .big__hero_desk {
    display: none;
  }
  .big__hero_mobile {
    display: block;
  }
  .main__sub__title {
    margin-top: 60px;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0.02em;
  }
}
@media (max-width: 450px) {
  .main__sub__title {
    margin-left: 0 !important;
    text-align: center;
  }
}
@media (max-width: 1000px) {
  .feature__item {
    align-items: center;
    width: 80%;
    max-width: 311px;
    padding: 40px 35px 20px 35px;
    height: 311px;
    background-image: url("https://backstage.global/img/started/mobileBack.png");
    background-repeat: no-repeat;
  }
  .feature__last_item {
    align-items: center;
    width: 80%;
    max-width: 311px;
    padding: 40px 35px 20px 35px;
    height: 311px;
    background-image: url("https://backstage.global/img/started/mobileBack2.png") !important;
    background-repeat: no-repeat;
  }
}
@media (max-width: 370px) {
  .feature__title {
    font-size: 17px;
    line-height: 23px;
  }
}
@media (max-width: 500px) {
  .feature__item, .feature__last_item {
    padding: 12px;
  }
}
