.container {
  width: 563px;
  /* min-height: 300px; */
  background-color: white;
  color: white;
  /* padding: 20px 40px; */
  border-radius: 10px;
  /* border: 1px solid rgb(31 49 39); */
  margin: auto;
  max-width: 80%;
  max-height: 100vh;
  overflow: auto;
}

@media (max-width: 576px) {
  .container {
    max-width: 95%;
  }
}

.top {
  width: 100%;
  position: relative;
}

.back {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.back_text {
  margin-left: 14px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  align-items: center;
  letter-spacing: 0.15em;

  color: #ffffff;
}

.title {
  width: 100%;
  /* text-align: center; */
  position: relative;
  font-size: 20px;
  font-weight: bold;
  margin-top: 27px;
}

.btn_close {
  width: 25px;
  height: 25px;
  padding-bottom: 2px;
  border-radius: 3px;
  cursor: pointer;
  position: absolute;
  right: 0px;
  top: 0px;
}

.wrapper {
  margin-top: 20px;
  font-size: 12px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.wrapper input {
  margin-top: 3px;
  margin-bottom: 5px;
  border: 1px solid rgba(255, 255, 255, 0.33);
  border-radius: 4px;
  height: 44px;
  font-size: 14px;
  width: 100%;
  padding: 0 10px;
  color: #fff;
  background: transparent;
  outline: none;
}

.wrapper input:hover {
  border: 1px solid #ffffff !important;
  filter: drop-shadow(0px 0px 8px #6a4dfd);
}

.wrapper input:focus {
  border: 1px solid #6a4dfd !important;
  filter: drop-shadow(0px 0px 8px #6a4dfd);
}

.wrapper select:focus {
  border: 1px solid #6a4dfd !important;
  filter: drop-shadow(0px 0px 8px #6a4dfd);
}

.wrapper select:hover {
  border: 1px solid #ffffff !important;
  filter: drop-shadow(0px 0px 8px #6a4dfd);
}

.wrapper select {
  margin-top: 3px;
  margin-bottom: 5px;
  border: 1px solid rgba(255, 255, 255, 0.33);
  border-radius: 4px;
  height: 44px;
  font-size: 14px;
  width: 100%;
  padding: 0 10px;
  color: #fff;
  background: transparent;
  outline: none;
}

.input_wrapper_full {
  width: 100%;
  position: relative;
}

.input_wrapper_half {
  width: 49%;
}

.warning_text {
  color: red;
}

.credit_card {
  position: absolute;
  top: 30px;
  right: 14px;
}

.credit_warning {
  font-size: 14px;
  color: red;
  text-align: center;
  margin-bottom: 0;
  font-weight: bold;
}

/* @media (max-width: 576px) {
  .container {
    padding: 20px 20px;
  }
} */

.info_block {
  background-color: rgba(255, 255, 255, 0.05);
  width: 100%;
  padding: 18px 25px;
  border-radius: 8px;
  margin-top: 14px;
  display: flex;
}

.info_text {
  margin: 0 0 0 16px;
  font-size: 14px;
  line-height: 20px;
}
.payment_message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: "";
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #6a4dfd;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #6a4dfd;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
