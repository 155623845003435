.post {
  position: relative;
  margin-top: 20px !important;
  margin-bottom: 0px;
  border-radius: 12px;
  width: 100%;
  max-width: 360px;
  margin: auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: calc(100% - 20px);
}
.post:hover {
  margin-top: 18px !important;
  margin-bottom: 2px;
  box-shadow: 0px 0px 8px #6a4dfd;
}
.post__img {
  display: block;
  width: 100%;
  position: relative;
  background-color: #000;
}
.post__img:before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  opacity: 0.1;
  transition: opacity 0.5s ease;
  z-index: 2;
}
.post__img img {
  width: 100%;
  position: relative;
  z-index: 1;
}
.post__img:hover:before {
  opacity: 0.3;
}
.post__img_blog {
  display: flex;
  align-items: center;
  /* height: 382px; */
  overflow: hidden;
  width: 100%;
  aspect-ratio: 1/1;
  position: relative;
  background-color: #1d1d33;
  padding: 16px 16px 0 16px;
}
.post__img_blog:before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  opacity: 0.1;
  transition: opacity 0.5s ease;
  z-index: 2;
}
.post__img_blog img {
  width: 358px;
  max-width: 100%;
  background: #3c3c4e;
  position: relative;
  z-index: 1;
  border-radius: 8px 8px 8px 8px;
}
.post__img_blog:hover:before {
  opacity: 0.3;
}
.post__content_blog {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 20px;
  background-color: #1d1d33;
  height: 200px;
  position: relative;
}
.post__category_blog {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  min-width: 60px;
  width: auto;
  color: rgba(255, 255, 255, 0.66);
  font-size: 12px;
  font-weight: 500;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50px;
  padding: 0 10px;
  margin-bottom: 10px;
  bottom: 100%;
  z-index: 2;
}
.post__title_blog {
  display: block;
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  color: #fff;
  margin-bottom: 20px;
  overflow: hidden;
  width: 100%;
  height: 80px;
  overflow: hidden;
}
.post__title_blog a {
  color: #fff;
  display: block;
  letter-spacing: 0.5px;
  line-height: 26px;
}
.post__title_blog a:hover {
  color: #6164ff;
}
@media (max-width: 450px) {
  .post__title_blog {
    height: auto;
    font-size: 20px;
  }
  .post__content_blog {
    height: auto;
  }
}
.post__content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 20px;
  background-color: #534f77;
  border-radius: 0 0 12px 12px;
  height: 100%;
  position: relative;
}
.post__category {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 28px;
  min-width: 70px;
  width: auto;
  color: #fff;
  font-size: 13px;
  background-color: #6164ff;
  border-radius: 10px;
  padding: 0 15px;
  margin-bottom: 20px;
  position: absolute;
  bottom: 100%;
  left: 20px;
  z-index: 2;
}
.post__category:hover {
  color: #fff;
  background-color: #534f77;
}
.post__title {
  display: block;
  font-weight: 400;
  font-size: 24px;
  line-height: 140%;
  color: #fff;
  margin-bottom: 20px;
  overflow: hidden;
  width: 100%;
}
.post__title a {
  color: #fff;
  display: block;
}
.post__title a:hover {
  color: #6164ff;
}
.post__meta {
  margin-top: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 20px;
}
.post__date {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.66);
}
.post__date svg {
  fill: #fff;
  width: 16px;
  height: auto;
  margin-right: 5px;
}
.post__date img {
  width: 16px;
  height: auto;
  margin-right: 5px;
}
.post__read_like {
  display: flex;
}
.dot {
  color: rgba(255, 255, 255, 0.66);
  margin: 0 9px;
}
@media (min-width: 768px) {
  .post {
    margin-top: 30px;
    height: calc(100% - 30px);
  }
}
