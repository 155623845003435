.container {
    width: 100%;
    overflow: auto;
    margin-top: 50px;
    padding-bottom: 20px;
}

.transaction_table {
    width: 100%;
    min-width: 950px;
}

.transaction_table th {
    color: rgba(255, 255, 255, 0.75);
    font-size: 12px;
    font-weight: 400;
    height: 48px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    padding: 0 5px
}

.transaction_table td {
    color: white;
    font-size: 12px;
    font-weight: 400;
    height: 48px;
    padding: 0 5px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.transaction__last_col {
    text-align: right !important;
}

.transaction_row:hover {
    background: rgba(255, 255, 255, 0.05);
}




.number_select {
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.33);
    display: flex;
    padding: 4px 10px;
    gap: 4px;
    position: relative;
}

.show__numbers_txt,
.show__entries_txt {
    font-size: 12px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.66);
    margin-bottom: 0;
}

.transaction__page_select {
    background: transparent;
    border: 0;
    color: white;
    font-size: 12px;
    padding-right: 20px;
    cursor: pointer;
    z-index: 2;
}

.transaction__page_select option {
    background: #6a4dfd;
}

.page__down_arrow {
    position: absolute;
    top: 1px;
    right: 3px;
}

.prev_page {
    cursor: pointer;
}

.next_page {
    cursor: pointer;
}

.tr__pagination_left {
    display: flex;
    align-items: center;
    gap: 12px;
}

.tr__pagination_right {
    display: flex;
    gap: 8px;
}

.transaction_pagination {
    display: flex;
    padding-top: 20px;
    justify-content: space-between;
}

@media (max-width: 450px) {
    .show__entries_txt {
        display: none;
    }

    .wallet_address {
        gap: 15px;
    }

    .available__bnb_amount {
        font-size: 32px;
    }

    .digital__item_txt {
        font-size: 16px;
    }

    .currencies__search_block {
        max-width: 130px;
    }

    .transaction_search {
        width: 100%;
        max-width: 100%;
    }
}

@media (max-width: 280px) {
    .available__bnb_amount {
        font-size: 24px;
    }

    .wallet_address {
        padding: 21px 0 !important;
    }
}