.container {
  width: 563px;
  background-color: #14142f;
  color: white;
  padding: 25px 20px;
  border-radius: 10px;
  border: 1px solid #534f77;
  margin: auto;
  max-width: 80%;
  max-height: 100vh;
  overflow: auto;
}

.nft_avatar {
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 10px;
}

@media (max-width: 576px) {
  .container {
    max-width: 95%;
  }
}

.top {
  width: 100%;
  position: relative;
}

.back {
  display: flex;
  align-items: center;
  cursor: pointer;
}


.btn_close {
  width: 25px;
  height: 25px;
  padding-bottom: 2px;
  border-radius: 3px;
  cursor: pointer;
  position: absolute;
  right: 6px;
  top: 6px;
  z-index: 100;
}

@media (max-width: 576px) {
  .container {
    padding: 10px;
  }
}

.title {
  font-size: 24px;
  line-height: 28px;
  margin: 15px 0 10px 0;
}
.description {
  font-size: 12px;
  opacity: 0.75;
  margin-bottom: 25px;
}
.attribute_contain {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  margin-top: 10px;
  gap: 20px;
}
.attribute {
  font-size: 14px;
  margin-bottom: 5px;
}
.value {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 5px;
  text-align: right;
}
.attribute_title {
  margin-top: 30px;
  font-size: 20px;
}
.tokenURL {
  word-break: break-all;
  font-size: 14px;
  color: #6a4dfd;
}
