.overview__container {
  margin-bottom: 50px;
  margin-top: 30px;
  padding: 0px;
  max-width: 1224px;
  width: 100%;
}

.title__container {
  display: flex;
  align-items: center;
}

.title {
  font-size: 24px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.02em;
  text-align: left;
  margin: 0;
  color: #fff;
  margin-left: 14px;
}

.top__part {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 60px;
}

@media (max-width: 700px) {
  .top__part {
    flex-direction: column;
    gap: 20px;
  }
}

.btn__group {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.category__btn {
  border: 0.63px solid #FFFFFFA8;
  padding: 8.21px 15.16px 8.21px 15.16px;
  border-radius: 75px;
  height: 32px;
  font-size: 12px;
  font-weight: 700;
  line-height: 15.31px;
  letter-spacing: 0.02em;
  text-align: center;
  color: #fff;
}

.active__btn {
  border: 0.63px solid #6A4DFD;
  padding: 8.21px 15.16px 8.21px 15.16px;
  border-radius: 75px;
  height: 32px;
  font-size: 12px;
  font-weight: 700;
  line-height: 15.31px;
  letter-spacing: 0.02em;
  text-align: center;
  color: #fff;
  background: #6A4DFD;
}

@media (max-width: 420px) {
  .category__btn {
    font-size: 12px;
    padding: 6px 12px;
  }
  .active__btn {
    font-size: 12px;
    padding: 6px 12px;
  }
}

.input__container {
  position: relative;
}

.search__input {
  border: 1px solid #FFFFFF54;
  height: 44px;
  border-radius: 4px;
  background-color: transparent;
  padding: 11px 50px 11px 17px;
  color: #fff;
}

@media (max-width: 400px) {
  .input__container {
    width: 100%;
  }
  .search__input {
    width: 100%;
  }
}

.search__icon {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 12px;
  right: 15px;
}

@media (max-width: 1250px) {
  .overview__container {
    padding: 0 10px;
  }
}