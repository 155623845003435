.container {
  overflow: auto;
}
.permission_table {
  width: 100%;
  min-width: 1200px;
}
.permission_table td {
  color: white;
  font-size: 14px;
  font-weight: 400;
  height: 48px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}
.permission_table th {
  color: rgba(255, 255, 255, 0.75);
  font-size: 14px;
  font-weight: 400;
  height: 48px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  min-width: 50px;
}
.permission_table th:last-child, td:last-child {
  text-align: center;
}
.select {
  border: 1px solid rgba(255, 255, 255, 0.33);
  border-radius: 4px;
  height: 40px;
  font-size: 14px;
  width: 100%;
  padding: 0 20px;
  color: #fff;
  background: transparent;
  outline: none;
  cursor: pointer;
  margin: 10px 0;
}
.bottom_arrow {
  position: absolute;
  top: 27px;
  right: 32px;
}
.ticket_field {
  position: relative;
  width: 280px;
  padding-right: 20px;
}
.check_box {
  background: white !important;
}
.bus__users {
  color: white;
  font-size: 18px;
  margin-bottom: 0;
}
.btn_download {
  border-radius: 4px;
  height: 35px;
  font-size: 14px;
  width: 100%;
  color: #fff;
  background: #6a4dfd;
  font-weight: 500;
  outline: none;
  width: 120px;
}
.top_container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}
