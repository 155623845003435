.table {
    width: 100%;
    min-width: 700px;
}

.table th {
    color: rgba(255, 255, 255, 0.75);
    font-size: 12px;
    font-weight: 400;
    height: 48px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.table td {
    color: white;
    font-size: 12px;
    font-weight: 400;
    height: 48px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}
