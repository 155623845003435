.sit_contain {
  position: relative;
}
.pieceMap {
  width: 100%;
  opacity: 1;
  cursor: pointer;
}
.pieceMap:hover {
  opacity: 0.6;
  /* border: 1px solid white; */
}

.stage {
  position: absolute;
  top: 13%;
  left: 42.5%;
  width: 15%;
}

.stage_img {
  width: 100%;
}

.zoom_out {
  position: absolute;
  bottom: 60px;
  left: 30px;
  z-index: 1000;
}

.zoom_in {
  position: absolute;
  bottom: 24px;
  left: 30px;
  z-index: 1000;
}

.front_orchestra_center {
  position: absolute;
  top: 7%;
  left: 22%;
  width: 9%;
}

.orchestra_center {
  position: absolute;
  top: 51.5%;
  left: 22%;
  width: 9%;
}

.front_orchestra_left_center {
  position: absolute;
  top: 7%;
  left: 12.5%;
  width: 11%;
}

.orchestra_left_center {
  position: absolute;
  top: 52%;
  left: 12.5%;
  width: 8.8%;
}

.front_orchestra_right_center {
  position: absolute;
  top: 7%;
  left: 29.5%;
  width: 11%;
}

.front_orchestra_right {
  position: absolute;
  top: 8.5%;
  left: 36%;
  width: 13%;
}

.front_orchestra_left {
  position: absolute;
  top: 8.5%;
  left: 4%;
  width: 13%;
}

.orchestra_left {
  position: absolute;
  top: 51%;
  left: 3.5%;
  width: 8%;
}

.orchestra_right_center {
  position: absolute;
  top: 52.5%;
  left: 32%;
  width: 8.8%;
}

.orchestra_right {
  position: absolute;
  top: 52%;
  left: 41.5%;
  width: 8%;
}

.front_orchestra_line {
  position: absolute;
  top: 4%;
  left: 2%;
  width: 49%;
}

.orchestra_line {
  position: absolute;
  top: 50%;
  left: 1.5%;
  width: 50%;
}

.loge_line {
  position: absolute;
  top: 24%;
  left: 55%;
  width: 41%;
}

.loge_section_1 {
  position: absolute;
  top: 30%;
  left: 69.5%;
  width: 5.5%;
}

.loge_section_3 {
  position: absolute;
  top: 28.5%;
  left: 62.5%;
  width: 6%;
}

.loge_section_4 {
  position: absolute;
  top: 28.5%;
  left: 82.5%;
  width: 6%;
}

.loge_section_5 {
  position: absolute;
  top: 26%;
  left: 56%;
  width: 6.5%;
}

.loge_section_6 {
  position: absolute;
  top: 26%;
  left: 88.5%;
  width: 6.5%;
}

.loge_section_2 {
  position: absolute;
  top: 30%;
  left: 76%;
  width: 5.5%;
}

.balcony_center {
  position: absolute;
  top: 42%;
  left: 72%;
  width: 8%;
}

.balcony_left_center {
  position: absolute;
  top: 40%;
  left: 62.5%;
  width: 9%;
}

.balcony_right_center {
  position: absolute;
  top: 40%;
  left: 80.5%;
  width: 9%;
}

.balcony_left_top {
  position: absolute;
  top: 37.5%;
  left: 56%;
  width: 7%;
}

.balcony_right_top {
  position: absolute;
  top: 37.5%;
  left: 89%;
  width: 7%;
}

.balcony_left_bottom {
  position: absolute;
  top: 49%;
  left: 55.5%;
  width: 7.5%;
}

.balcony_right_bottom {
  position: absolute;
  top: 49%;
  left: 89%;
  width: 7.5%;
}

.balcony_line {
  position: absolute;
  top: 35.5%;
  left: 54%;
  width: 44.6%;
}
