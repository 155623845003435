@font-face {
  font-family: "custom-font";
  src: url("SpaceGrotesk-VariableFont_wght.ttf");
}

body {
  margin: 0;
  font-family: custom-font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: custom-font, source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}

* {
  font-family: custom-font !important;
}
