@media (max-width: 1256px) {
    .detail_container {
        margin: 0 16px;
    }
}

.item__detail_container {
    padding: 0;
    z-index: 999;
    min-height: 400px;
    max-width: 1224px;
    width: 100%;
}

@media (max-width: 1235px) {
    .item__detail_container {
        padding: 0 10px;
    }
}

@media (max-width: 770px) {
    .item__detail_container {
        padding: 20px;
    }
}

.update__name {
    color: #fff;
    margin-top: 50px;
    margin-bottom: 30px;
}

.main__banner {
    overflow: hidden;
    position: relative;
    display: flex;
}

.main__banner>img {
    border-radius: 12px 12px 0px 0px;
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: center;
}

@media (max-width: 1256px) {

    .main__banner {
        padding: 0 16px;
    }
}