.modal_contain {
  max-width: 90%;
  width: 480px;
  background-color: #14142f;
  margin: auto;
  border-radius: 8px;
}
.bottom {
  padding: 25px 23px 31px 25px;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 0 0 8px 8px;
}
.container {
  /* max-width: 90%; */
  /* width: 480px; */
  background-color: #14142f;
  color: white;
  padding: 20px 23px 0;
  border-radius: 12px;
  margin: auto;
  position: relative;
}
.success_part_contain {
  /* max-width: 90%; */
  /* width: 480px; */
  background: radial-gradient(
    80% 50% at 50% -20%,
    rgba(5, 255, 0, 0.25) 0%,
    rgba(5, 255, 0, 0) 100%
  );
  background-color: #14142f;
  color: white;
  padding: 20px 23px 0;
  border-radius: 12px;
  margin: auto;
  position: relative;
}
@media (max-width: 450px) {
  .container {
    padding: 20px 23px 30px;
  }
  .btn_close {
    right: 15px;
    width: 24px;
    height: 24px;
  }
}
.btn_close {
  position: absolute;
  top: 20px;
  right: 23px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.btn_crypto {
  border: 1px solid rgba(255, 255, 255, 0.33);
}
.info_text {
  color: red;
  margin: 10px 0;
}
.digital__item__block {
  margin-top: 14px;
  border-radius: 8px;
  padding: 14px 0 24px 0;
  background: rgba(255, 255, 255, 0.05);
  text-align: center;
}
.address_container {
  margin: 26px 0 22px 0;
}
.amount_container {
  margin: 22px 0 35px 0;
}
.amount_block {
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.33);
  padding: 12px 11px 11px 20px;
  display: flex;
  justify-content: space-between;
}
.amount_input {
  border: 0;
  background: transparent;
  color: white;
  min-width: 1ch;
  max-width: 200px;
  margin-right: 6px;
}
.price_amount_txt {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 0;
}
.btn_max {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 30px;
  font-size: 16px;
  color: white;
  padding: 12px 20px;
}
.btn_cancel {
  width: 100%;
  font-weight: 700;
  font-size: 14px;
  color: white;
  letter-spacing: 0.15em;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.33);
  padding: 11px 0;
  text-transform: uppercase;
}
.btn_cancel:hover {
  border-color: white;
}
.btn_next {
  width: 100%;
  font-weight: 700;
  font-size: 14px;
  color: white;
  letter-spacing: 0.15em;
  background: rgba(106, 77, 253, 1);
  border-radius: 4px;
  padding: 11px 0;
  text-transform: uppercase;
}
.btn_next:hover {
  filter: drop-shadow(0px 0px 8px #6a4dfd);
}
.divide_line {
  background: rgba(255, 255, 255, 0.15);
  height: 1px;
  margin: 24px 0 20px 0;
}
.contain_fee {
  display: flex;
  justify-content: space-between;
  margin: 21px 0 14px 0;
}
.txt_network {
  color: rgba(255, 255, 255, 0.66);
  font-size: 14px;
}
.contain_wallet {
  padding: 15px 17px 16px 17px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
}
.wallet_item {
  display: flex;
  align-items: center;
  gap: 9px;
}
.avatar {
  width: 36px;
  border-radius: 36px;
}
.user_name {
  display: flex;
  gap: 4px;
}
.btn_exit {
  width: 100%;
  font-weight: 700;
  font-size: 16px;
  color: white;
  letter-spacing: 0.15em;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.33);
  padding: 11px 0;
  margin: 20px 0 23px 0;
  text-transform: uppercase;
}
.btn_exit:hover {
  border-color: white;
}
.success_icon {
  margin-top: 15px;
}
.success_title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 0;
}
.success_text {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.8);
  max-width: 300px;
  margin: auto;
}
.balance_top_txt {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.66);
  margin-bottom: 11px;
}
.balance_txt {
  font-size: 32px;
  color: white;
  margin-bottom: 0;
  font-weight: 700;
}
.price_txt {
  font-size: 20px;
  color: white;
  margin-bottom: 0;
}
.logo_contain {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}
.sending_txt {
  font-size: 14px;
  color: white;
  margin-bottom: 0;
  margin-top: 3px;
}
.sending_amount_txt {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 4px;
  margin-top: 10px;
}
.sending_price {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.66);
  margin-bottom: 0;
}
