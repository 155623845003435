.overview__container {
  margin-bottom: 50px;
  margin-top: 30px;
  padding: 0px;
  max-width: 1224px;
  width: 100%;
  min-height: 300px;
}

.title__container {
  display: flex;
  align-items: center;
}

.top__part {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
}

.title {
  font-size: 30px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0.02em;
  text-align: center;
  margin: 0;
  color: #fff;
}

.create__btn {
  border: 1px solid #6a4dfd;
  background-color: #6a4dfd;
  color: white;
  font-size: 14px;
  font-weight: 700;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.create__btn:hover {
  filter: drop-shadow(0 0 8px #6a4dfd);
}

.create__disable_btn {
  border: 1px solid #6a4dfd;
  background-color: #6a4dfd;
  color: white;
  font-size: 14px;
  font-weight: 700;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  opacity: 0.5;
}

.top__part {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 60px;
}

.table__container {
  width: 100%;
  overflow: auto;
  margin-top: 50px;
}

.transaction_pagination {
  display: flex;
  padding-top: 20px;
  justify-content: space-between;
}

.tr__pagination_left {
  display: flex;
  align-items: center;
  gap: 12px;
}

.number_select {
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.33);
  display: flex;
  padding: 4px 10px;
  gap: 4px;
  position: relative;
}

.show__numbers_txt,
.show__entries_txt {
  font-size: 12px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.66);
  margin-bottom: 0;
}

.transaction__page_select {
  background: transparent;
  border: 0;
  color: white;
  font-size: 12px;
  padding-right: 20px;
  cursor: pointer;
  z-index: 10;
}
.transaction__page_select option {
  background: #6a4dfd;
}

.page__down_arrow {
  position: absolute;
  top: 1px;
  right: 3px;
  /* z-index: -1; */
}
.prev_page {
  cursor: pointer;
}
.next_page {
  cursor: pointer;
}
.tr__pagination_left {
  display: flex;
  align-items: center;
  gap: 12px;
}
.tr__pagination_right {
  display: flex;
  gap: 8px;
}

@media (max-width: 700px) {
  .top__part {
    flex-direction: column;
    gap: 20px;
  }
}

.btn__group {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.category__btn {
  border: 0.63px solid #FFFFFFA8;
  padding: 8.21px 15.16px 8.21px 15.16px;
  border-radius: 75px;
  height: 32px;
  font-size: 12px;
  font-weight: 700;
  line-height: 15.31px;
  letter-spacing: 0.02em;
  text-align: center;
  color: #fff;
}

.active__btn {
  border: 0.63px solid #6A4DFD;
  padding: 8.21px 15.16px 8.21px 15.16px;
  border-radius: 75px;
  height: 32px;
  font-size: 12px;
  font-weight: 700;
  line-height: 15.31px;
  letter-spacing: 0.02em;
  text-align: center;
  color: #fff;
  background: #6A4DFD;
}

@media (max-width: 420px) {
  .category__btn {
    font-size: 12px;
    padding: 6px 12px;
  }

  .active__btn {
    font-size: 12px;
    padding: 6px 12px;
  }
}

.input__container {
  position: relative;
}

.search__input {
  border: 1px solid #FFFFFF54;
  height: 44px;
  border-radius: 4px;
  background-color: transparent;
  padding: 11px 50px 11px 17px;
  color: #fff;
}

@media (max-width: 400px) {
  .input__container {
    width: 100%;
  }

  .search__input {
    width: 100%;
  }
}

.search__icon {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 12px;
  right: 15px;
}

@media (max-width: 1250px) {
  .overview__container {
    padding: 0 10px;
  }
}