.report__container {
  margin-bottom: 50px;
  margin-top: 30px;
  padding: 0px;
  z-index: 999;
  max-width: 1224px;
  width: 100%;
}

.link__top_part {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.link__container {
  margin-top: 50px;
}

.title {
  flex: 1 1;
  font-weight: 700;
  font-size: 40px;
  line-height: 44px;
  letter-spacing: 0.02em;
  color: #ffffff;
  margin-bottom: 0px;
}

.download__btn {
  border-radius: 4px;
  height: 44px;
  font-size: 16px;
  padding: 0 20px;
  color: #fff;
  background: #6a4dfd;
  font-weight: 700;
  outline: none;
  width: 200px;
}

.table__container {
  height: 500px;
  width: 100%;
  border-radius: 18px;
  border: 1px solid rgba(255, 255, 255, 0.33);
  overflow: hidden;
  margin-top: 24px;
}

@media (max-width: 1240px) {
  .report__container {
    padding: 10px;
    margin-top: 0;
    margin-bottom: 20px;
  }
}

@media (max-width: 900px) {
  .link__top_part {
    flex-direction: column;
    gap: 20px;
  }
  .title {
    font-size: 24px;
  }
}