.container {
  width: 563px;
  background-color: #14142f;
  color: white;
  padding: 20px 40px;
  border-radius: 10px;
  border: 1px solid #534f77;
  margin: auto;
  max-width: 80%;
}

@media (max-width: 576px) {
  .container {
    max-width: 95%;
  }
}

.top {
  width: 100%;
  position: relative;
}

.back {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.back_text {
  margin-left: 14px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  align-items: center;
  letter-spacing: 0.15em;

  color: #ffffff;
}

.title {
  width: 100%;
  /* text-align: center; */
  position: relative;
  font-size: 20px;
  font-weight: bold;
  margin-top: 27px;
}

.btn_close {
  width: 25px;
  height: 25px;
  padding-bottom: 2px;
  border-radius: 3px;
  cursor: pointer;
  position: absolute;
  right: 0px;
  top: 0px;
}

.wrapper {
  margin-top: 20px;
  font-size: 12px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.wrapper input {
  margin-top: 3px;
  margin-bottom: 5px;
  border: 1px solid rgba(255, 255, 255, 0.33);
  border-radius: 4px;
  height: 44px;
  font-size: 14px;
  width: 100%;
  padding: 0 10px;
  color: #fff;
  background: transparent;
  outline: none;
}

.wrapper input:hover {
  border: 1px solid #ffffff !important;
  filter: drop-shadow(0px 0px 8px #6a4dfd);
}

.wrapper input:focus {
  border: 1px solid #6a4dfd !important;
  filter: drop-shadow(0px 0px 8px #6a4dfd);
}

.wrapper select:focus {
  border: 1px solid #6a4dfd !important;
  filter: drop-shadow(0px 0px 8px #6a4dfd);
}

.wrapper select:hover {
  border: 1px solid #ffffff !important;
  filter: drop-shadow(0px 0px 8px #6a4dfd);
}

.wrapper select {
  margin-top: 3px;
  margin-bottom: 5px;
  border: 1px solid rgba(255, 255, 255, 0.33);
  border-radius: 4px;
  height: 44px;
  font-size: 14px;
  width: 100%;
  padding: 0 10px;
  color: #fff;
  background: transparent;
  outline: none;
}

.input_wrapper_full {
  width: 100%;
  position: relative;
  margin-bottom: 10px;
}

.input_wrapper_half {
  width: 49%;
}

.warning_text {
  color: red;
}

.credit_card {
  position: absolute;
  top: 30px;
  right: 14px;
}

.credit_warning {
  font-size: 14px;
  color: red;
  text-align: center;
  margin-bottom: 0;
  font-weight: bold;
}

@media (max-width: 576px) {
  .container {
    padding: 20px 20px;
  }
}
