.share__item {
  display: flex;
  align-items: center;
  color: #FFFFFFA8;
  font-size: 14px;
  gap: 8px;
  margin-bottom: 15px;
  cursor: pointer;
}

.share__item img {
  width: 20px !important;
  height: 20px !important;
  background: transparent !important;
  margin-bottom: 0 !important;
}

.share__item:hover {
  color: white;
}

.share__item:hover svg path {
  fill-opacity: 1;
  stroke-opacity: 1;
}

.divide__line {
  border-bottom: 1px solid #FFFFFF1A;
  margin-bottom: 20px;
}