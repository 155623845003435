.previewBlock {
    position: relative;
    width: fit-content;
}

.trashImg {
    position: absolute;
    top: 20px;
    right: 6px;
    cursor: pointer;
}

.largeImgPreview {
    width: 200px;
    height: 200px;
    border-radius: 6px;
    margin-top: 14px;
}

.smallImgPreview {
    width: 100px;
    height: 100px;
    border-radius: 3px;
    margin-top: 14px;
}

.label {
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    letter-spacing: 0.15rem;
    padding-left: 23px;
    margin-top: 60px;
    margin-bottom: 5px;
}

.multiContainer {
    display: flex;
    flex-wrap: wrap;
    row-gap: 0;
    column-gap: 12px;
}

.benefitNameBlock {
    width: 45%;
}

@media (max-width: 550px) {
    .benefitNameBlock {
        width: 100%;
    }
}

.benefitPriceBlock {
    flex: 1;
    position: relative;
}

.benefitIconBlock {
    margin-top: 15px;
}

.benefitDescriptionBlock {
    width: 100%;
}

.fiatSymbol {
    position: absolute;
    color: white;
    right: 14px;
    bottom: 10px;
}

.benefitImagelabelTitle {
    font-size: 14px;
    font-weight: 700;
    line-height: 12px;
    letter-spacing: 0.1em;
    color: #fff;
    margin-bottom: 0px;
    margin-top: 20px;
}

.descriptionInput {
    margin-top: 10px;
    border: 1px solid rgba(255, 255, 255, 0.33);
    border-radius: 4px;
    font-size: 16px;
    width: 100%;
    padding: 10px 20px;
    color: #fff;
    background: transparent;
    outline: none;
}

.descriptionInput:hover {
    border-color: #fff;
}

.descriptionInput:focus {
    border: 1px solid #6a4dfd;
}

.labelDescription {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.05em;
    color: rgba(255, 255, 255, 0.66);
    margin: 5px 0 0 0;
}

.img__addon-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 22px;
    width: 32px;
    height: 32px;
    margin-right: 20px;
}