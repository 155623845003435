.search__container {
  position: relative;
  display: flex;
  width: 100%;
  max-width: 380px;
  transition: top 0.5s ease;
}
.search__container input {
  border: 1px solid rgba(255, 255, 255, 0.33);
  border-radius: 4px;
  font-size: 14px;
  color: #fff;
  height: 44px;
  background-color: transparent;
  font-weight: 400;
}
.search__container input:focus {
  border-color: #fff;
}
.search__container button {
  position: absolute;
  top: 8px;
}
.search__container button svg {
  width: 20px;
  height: auto;
  fill: #bdbdbd;
  transition: 0.5s ease;
}
.search__container button:hover svg {
  fill: #6164ff;
}
.search__container button.close {
  right: 15px;
}
@media (min-width: 576px) {
  .search__container {
    padding: 0 30px;
  }
  .search__container button {
    right: 75px;
  }
  .search__container button.close {
    right: 30px;
  }
}
@media (max-width: 450px) {
  .search__container {
    max-width: 450px !important;
  }
}
@media (max-width: 768px) {
  .search__container input {
    display: flex;
    width: 100%;
    padding: 15px;
  }
  .search__container input::placeholder {
    color: rgba(255, 255, 255, 0.33);
    letter-spacing: 0.02em;
    font-size: 14px;
    font-weight: 400;
  }
  .search__container button {
    right: 15px;
  }
  .search__container button svg {
    fill: rgba(255, 255, 255, 0.33);
  }
}
.search__container {
  position: relative;
  top: auto;
  left: auto;
  padding: 0;
  border: none;
  background-color: transparent;
}
.search__container input {
  padding: 0 60px 0 20px;
  width: 100%;
}
.search__container button {
  right: 20px;
}
.search__container button.close {
  display: none;
}
