.modal_contain {
  max-width: 90%;
  width: 480px;
  background-color: #14142f;
  margin: auto;
  border-radius: 8px;
  padding: 20px 23px 31px 23px;
}
.modal_title {
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  letter-spacing: 0.02em;
}
.btn_close {
  position: absolute;
  top: 22px;
  right: 40px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.top_part {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  padding-top: 16px;
  padding-bottom: 20px;
  margin-top: 18px;
  margin-bottom: 28px;
  text-align: center;
}
.token_amount {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 4px;
  color: white;
  margin-top: 10px;
}
.token_price {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
  color: white;
}
.detail_info {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  margin-top: 16px;
}
.property {
  color: rgba(255, 255, 255, 0.66);
  font-size: 12px;
  margin-bottom: 5px;
  text-transform: uppercase;
}
.value {
  color: white;
  font-size: 16px;
  margin-bottom: 13px;
  overflow-wrap: break-word;
}
.status_block {
  display: flex;
  margin-bottom: 13px;
  gap: 4px;
}
.bottom_btn {
  border: 1px solid rgba(255, 255, 255, 0.33);
  border-radius: 4px;
  padding: 12px 0;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  color: white;
  width: 100%;
  letter-spacing: 0.15em;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 350px) {
  .bottom_btn {
    font-size: 12px;
  }
  .token_amount {
    font-size: 26px;
  }
  .modal_contain {
    padding: 20px 10px;
  }
  .btn_close {
    right: 22px;
  }
}
@media (max-width: 290px) {
  .bottom_btn {
    font-size: 11px;
  }
}
