.faq__block {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding: 28px 50px 28px 28px;
  max-width: 808px;
  margin: 0 auto 16px auto;
  position: relative;
}
.faq__block_question {
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  color: white;
  margin-bottom: 0;
  cursor: pointer;
}
.faq__minus {
  position: absolute;
  top: 24px;
  right: 20px;
  cursor: pointer;
}
.faq__block_answer {
  color: rgba(255, 255, 255, 0.66);
  font-size: 16px;
  margin-top: 14px;
}
.faq__block_answer ol {
  counter-reset: section;
  padding-left: 0;
}

.faq__block_answer ol > li {
  display: block;
}

.faq__block_answer ol > li::before {
  counter-increment: section;
  content: counters(section, ".") ".";
}
