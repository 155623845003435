.about__container {
  align-items: center;
  margin-top: 70px;
  margin-bottom: 70px;
  z-index: 999;
  max-width: 1224px;
  width: 100%;
}
.about__block {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.about__title {
  color: #fff;
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 0.03em;
  margin-bottom: 50px;
}
.about__description {
  color: rgba(255, 255, 255, 0.66);
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  margin-top: 25px;
  padding: 0px;
  width: 430px;
  letter-spacing: 0.5px;
}
.about__follow_title {
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  width: 360px;
  text-align: center;
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  text-transform: capitalize;
  letter-spacing: 0.02em;
  padding-top: 35px;
  margin-top: 60px;
  margin-bottom: 10px;
}
.follow__block {
  display: flex;
  flex-direction: column;
}
.about__follow_icons {
  display: flex;
  justify-content: space-between;
  width: 165px;
  margin-top: 12px;
}
.about__follow_icons a {
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.06);
  border-radius: 4px;
}
.about__follow_icons img {
  width: 24px;
  height: 24px;
}
@media (max-width: 560px) {
  .about__container {
    margin-top: 30px;
  }
  .about__title {
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.03em;
    margin-bottom: 40px;
  }
  .about__description {
    padding: 0px 20px;
    width: 100%;
  }
}
