.token_block {
  background: rgba(255, 255, 255, 0.03);
  border-radius: 8px;
  padding: 24px 16px;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.token_block:hover {
  background: rgba(255, 255, 255, 0.1);
}
.price_txt {
  font-size: 20px;
  font-weight: 700;
  color: white;
  margin-bottom: 0;
  position: relative;
}
.total__balance_txt {
  font-size: 10px;
  position: absolute;
  color: rgba(255, 255, 255, 0.66);
  margin-bottom: 0;
  top: -13px;
  font-weight: 400;
  letter-spacing: 1px;
}
.bnb__balance_block {
  display: flex;
  align-items: center;
  gap: 40px;
}
.mobile__price_txt {
  font-size: 16px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.66);
  margin-bottom: 0;
}
.coin_img {
  width: 48px;
  border-radius: 48px;
}
.token_info {
  display: flex;
  align-items: center;
  gap: 14px;
}
@media (max-width: 470px) {
  .price_txt {
    font-size: 16px;
  }
  .mobile__price_txt {
    display: block;
    font-size: 12px;
  }
  .bnb__balance_block {
    gap: 30px;
  }
  .coin_img {
    width: 36px;
  }
}
@media (max-width: 360px) {
  .bnb__balance_block {
    gap: 15px;
  }
}
