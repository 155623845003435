.filter__sort_container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
}

.filter__sort_container button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    width: 125px;
    height: 44px;
    border-radius: 4px;
    color: white;
}

.filter__sort_container>div {
    position: relative;
}

.btn_active {
    border: 1px solid #6a4dfd;
}

.btn_inactive {
    border: 1px solid rgba(255, 255, 255, 0.66);
}

.btn_text {
    margin: 0px;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.15em;
    text-transform: uppercase;
}

.filter__options_contain {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
    right: 0;
    top: 55px;
    padding: 20px 18px;
    z-index: 555;
    background: #0d0d21;
    border: 1px solid rgba(255, 255, 255, 0.33);
    border-radius: 8px;
    width: 200px;
    z-index: 99999;
}

.sort__options_contain {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
    right: 0;
    top: 55px;
    padding: 20px 18px;
    z-index: 555;
    background: #0d0d21;
    border: 1px solid rgba(255, 255, 255, 0.33);
    border-radius: 8px;
    width: 200px;
    z-index: 99999;
}

@media (max-width: 450px) {
    .sort__options_contain {
        gap: 5px;
        justify-content: space-between;
        right: 0;
        padding: 20px 23px;
        z-index: 555;
    }
    .filter__options_contain {
        gap: 5px;
        justify-content: space-between;
        left: 0;
        padding: 20px 23px;
        z-index: 555;
    }
}

@media (max-width: 576px) {

    .filter__sort_container {
        justify-content: space-between;
        width: 100%;
    }

    .filter__sort_container button {
        margin-left: 0px;
        padding: 0 15px;
        width: 115px;
    }

    .filter__sort_container button:last-child {
        margin-right: 0px;
    }

    .filter__sort_container>div {
        justify-content: space-between;
    }
}

@media (max-width: 288px) {
    .filter__sort_container {
        flex-direction: column;
        gap: 10px !important;
    }
}