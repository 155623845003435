.full__container {
    max-width: 1224px;
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 0px;
    width: 100%;
    overflow: auto;
    min-height: 300px;
}

.content {
    width: 480px;
    max-width: 90%;
    margin: auto;
}

.top__part {
    width: 100%;
    background: #FFFFFF0D;
    padding: 30px 25px;
    border-radius: 8px 8px 0px 0px;
}

.name__text {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    color: #fff;
    text-align: center;
}

.content__text {
    font-size: 20px;
    font-weight: 500;
    line-height: 22px;
    margin: 15px 0;
    color: #19b826;
    text-align: center;
}

.number__text {
    color: #FFFFFFA8;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    max-width: 280px;
    margin: auto;
}

.body__part {
    border: 1px solid #FFFFFF1A;
    width: 100%;
    padding: 40px 0 80px 0;
    background: radial-gradient(54% 90% at 50% 0%, rgba(92, 197, 75, 0.33) 0%, rgba(92, 197, 75, 0) 100%);
    text-align: center;
}

.approve__text {
    font-size: 20px;
    font-weight: 700;
    color: #fff;
    margin: 10px 0 6px 0;
}

.fail__part {
    border: 1px solid #FFFFFF1A;
    width: 100%;
    padding: 40px 0 80px 0;
    background: radial-gradient(54% 90% at 50% 0%, rgba(255, 0, 0, 0.33) 0%, rgba(255, 0, 0, 0) 100%);
    text-align: center;
}