.assets_title {
  font-size: 24px;
  color: white;
  font-weight: 700;
  margin-bottom: 24px;
}
.assets__menu_item {
  display: flex;
  gap: 13px;
  align-items: center;
  padding: 10px 13px;
  width: 288px;
  height: 44px;
  cursor: pointer;
  margin-bottom: 8px;
  opacity: 0.66;
  border-left: 4px solid transparent;
}
.assets__menu_item_active {
  display: flex;
  gap: 13px;
  align-items: center;
  padding: 10px 13px;
  width: 288px;
  height: 44px;
  cursor: pointer;
  margin-bottom: 8px;
  border-radius: 4px 8px 8px 4px;
  background: rgba(255, 255, 255, 0.05);
  border-left: 4px solid #6a4dfd;
  opacity: 1;
}
.assets__menu_img {
  width: 25px;
}
.menu__item_text {
  font-size: 16px;
  font-weight: 700;
  color: white;
  margin-bottom: 0;
}
.red_offer {
  width: 14px;
  height: 14px;
  border-radius: 14px;
  background: #ff0000;
  font-size: 10px;
  color: white;
  font-weight: 700;
  text-align: center;
  margin-left: -7px;
}
.wallet_content {
  flex: 1;
  border-radius: 8px;
  padding: 22px 18px;
  overflow: hidden;
}
.assets__mobile_title {
  display: none;
}
.wallet_info {
  display: flex;
  gap: 12px;
}
.bnb_balance {
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.03);
  padding: 21px;
  width: 50%;
}
.wallet_address {
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.03);
  padding: 21px;
  width: 50%;
  display: flex;
  gap: 40px;
  justify-content: space-between;
}
.available__bnb_txt {
  color: rgba(255, 255, 255, 0.66);
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.12em;
}
.available__bnb_amount {
  font-size: 36px;
  font-weight: 700;
  color: white;
  margin-bottom: 0;
}
.bnb__price_txt {
  font-size: 16px;
  color: white;
}
.text_wallet {
  display: flex;
  gap: 8px;
  padding: 12px 16px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 44px;
  width: fit-content;
  margin: auto;
  position: relative;
}
.address_value {
  color: white;
  font-size: 12px;
  margin-bottom: 0;
}
.digital__item_txt {
  font-size: 24px;
  font-weight: 700;
  color: white;
  margin-bottom: 0;
}

.digital__item_block {
  margin-top: 45px;
  padding: 21px;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 8px;
}
.view__all_btn {
  border: 1px solid rgba(255, 255, 255, 0.33);
  border-radius: 4px;
  height: 40px;
  width: 136px;
  color: white;
  font-size: 14px;
  font-weight: 700;
}
.view__all_btn:hover {
  border: 1px solid white;
}
.view__btn_block {
  margin-top: 20px;
  text-align: center;
}
.currencies_block {
  margin-top: 50px;
  padding: 21px;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 8px;
}

.add__asset_btn {
  border-radius: 6px;
  height: 40px;
  width: 136px;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.5);
}
.add__asset_btn:hover {
  border: 1px solid white;
}

.wallet__title_part {
  display: flex;
  align-items: center;
  gap: 9px;
  margin-bottom: 22px;
}
.wallet__content_title {
  font-size: 16px;
  font-weight: 700;
  color: white;
  margin-bottom: 0;
  line-height: 20px;
}
.assets_number {
  font-size: 12px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.66);
  margin-bottom: 0;
}
.currencies__top_part {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  margin-bottom: 23px;
  flex-wrap: wrap;
}

.currencies__search_part {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  justify-content: space-between;
}
.assets_tab {
  display: flex;
  gap: 34px;
  margin-top: 36px;
}
.active_asset {
  font-size: 16px;
  font-weight: 700;
  color: white;
  border-bottom: 2px solid #6a4dfd;
  padding-bottom: 9px;
  cursor: pointer;
}
.inactive_asset {
  font-size: 16px;
  font-weight: 700;
  color: rgba(255, 255, 255, 0.66);
  cursor: pointer;
}
.divide__vertical_line {
  background: rgba(255, 255, 255, 0.33);
  height: 100%;
  width: 1px;
  margin: 0 15px;
}
.transaction_table_contain {
  width: 100%;
  overflow-x: auto;
}
.qrcode_container {
  padding: 5px;
  background: white;
  border-radius: 6px;
  width: fit-content;
  margin: auto;
}
.overview__currencies_part {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
}
.currencies__search_block {
  max-width: 238px;
}
.sent_received {
  display: flex;
  gap: 6px;
}
.transaction__top_btns {
  display: flex;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;
}
.transaction__top_btns {
  gap: 20px;
}
.transaction__top_btns button {
  display: flex;
  align-items: center;
  text-align: center;
  margin-bottom: 0px;
  height: 34px;
  padding: 8px 15px;
  border-radius: 22px;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.02em;
  color: #fff;
  z-index: 10;
  justify-content: center;
}
.number_select {
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.33);
  display: flex;
  padding: 4px 10px;
  gap: 4px;
  position: relative;
}
.show__numbers_txt,
.show__entries_txt {
  font-size: 12px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.66);
  margin-bottom: 0;
}
.transaction__page_select {
  background: transparent;
  border: 0;
  color: white;
  font-size: 12px;
  padding-right: 20px;
  cursor: pointer;
}
.transaction__page_select option {
  background: #6a4dfd;
}
.page__down_arrow {
  position: absolute;
  top: 1px;
  right: 3px;
  z-index: -1;
}
.prev_page {
  cursor: pointer;
}
.next_page {
  cursor: pointer;
}
.tr__pagination_left {
  display: flex;
  align-items: center;
  gap: 12px;
}
.tr__pagination_right {
  display: flex;
  gap: 8px;
}
.transaction_pagination {
  display: flex;
  padding-top: 20px;
  justify-content: space-between;
}
.offers_block {
  margin: 13px 0 38px 0;
}
.available_offer {
  font-size: 16px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.66);
  margin-bottom: 21px;
}
.transaction_search {
  max-width: 270px;
}
.digital__item_search {
  max-width: 270px;
}
.regular_staking {
  display: flex;
  border: 1px solid rgba(255, 255, 255, 0.66);
  border-radius: 76px;
  padding: 3.5px;
  width: fit-content;
}
.regular__staking_active__tag {
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: rgba(255, 255, 255, 0.1);
}
@media (max-width: 1200px) {
  .wallet_info {
    flex-direction: column;
  }
  .bnb_balance {
    width: 100%;
  }
  .wallet_address {
    width: 100%;
  }
  .digital__item_search {
    max-width: 200px;
  }
}
@media (max-width: 998px) {
  .assets__menu_item {
    width: 200px !important;
  }
  .assets__menu_item_active {
    width: 200px !important;
  }
  .currencies__top_part {
    flex-direction: column;
  }
  .digital__item_search {
    max-width: 494px;
    width: 100%;
  }
}
@media (max-width: 768px) {
  .currencies_block {
    padding: 0;
    background: transparent;
    border-radius: 0;
    margin-top: 63px;
  }
  .digital__item_block {
    padding: 0;
    background: transparent;
    border-radius: 0;
    margin-top: 40px;
  }
  .side_menu {
    display: none;
  }
  .wallet_content {
    background: transparent;
  }
  .assets__mobile_title {
    font-size: 24px;
    color: white;
    font-weight: 700;
    margin-bottom: 24px;
    display: block;
    text-align: center;
  }
  .wallet_address {
    flex-direction: column;
    gap: 20px;
  }
  .available__bnb_txt {
    text-align: center;
  }
  .available__bnb_amount {
    text-align: center;
  }
  .bnb__price_txt {
    text-align: center;
  }
}
@media (max-width: 489px) {
  .transaction_pagination {
    display: none !important;
  }
}
@media (max-width: 450px) {
  .show__entries_txt {
    display: none;
  }
  .wallet_address {
    gap: 15px;
  }
  .available__bnb_amount {
    font-size: 32px;
  }
  .digital__item_txt {
    font-size: 16px;
  }
  .currencies__search_block {
    max-width: 130px;
  }
  .transaction_search {
    width: 100%;
    max-width: 100%;
  }
}
@media (max-width: 280px) {
  .available__bnb_amount {
    font-size: 24px;
  }
  .wallet_address {
    padding: 21px 0 !important;
  }
}
