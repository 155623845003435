.contact__main_title {
  font-size: 40px;
  font-weight: 700;
  color: #fff;
  letter-spacing: 0.03em;
  margin-top: 0px;
}
.contact__socials {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 312px;
  border-top: 1px solid rgba(255, 255, 255, 0.33);
  padding-top: 30px;
  margin-top: 30px;
}
.contact__socials p {
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.02em;
  text-align: center;
  padding: 10px;
}
@media (max-width: 576px) {
  .contact__main_title {
    font-size: 20px;
  }
}
