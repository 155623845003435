.wrapper {
  position: fixed !important;
  z-index: 1000;
  bottom: 2px;
  right: 40px;
  max-width: 420px;
  padding: 20px 50px;
  background-color: #14142f;
  border: 1px solid #887bff;
  color: white;
  border-radius: 16px;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.title {
  font-size: 18px;
  font-weight: 700;
}

.content {
  font-size: 16px;
}

.buttonWrapper {
  display: flex;
  flex-direction: row-reverse;
  gap: 20px;
}

.button {
  display: flex;
  cursor: pointer;
}

.button:hover {
  filter: drop-shadow(0px 0px 8px #6a4dfd);
  transform: translate(0, -2px);
}

.button_title {
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 16px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0.15em;
  background-color: #6a4dfd;
  color: white;
  border-radius: 6px;
}

@media (max-width: 768px) {
  .wrapper {
    position: fixed !important;
    z-index: 1000;
    bottom: 2px;
    left: 20px;
    right: 20px;
    max-width: 420px;
    padding: 20px 20px;
    background-color: #14142f;
    border: 1px solid #887bff;
    color: white;
    border-radius: 16px;
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}
