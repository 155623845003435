.faq__container {
  margin-top: 70px;
  max-width: 1224px;
  width: 100%;
}
.faq__title {
  color: #fff;
  font-weight: 700;
  font-size: 40px;
  text-align: center;
}
.faq__text {
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  margin-bottom: 20px;
  width: 100%;
  color: #bdbdbd;
}
.faq__description {
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  width: 100%;
  color: #bdbdbd;
  text-align: center;
  max-width: 536px;
  margin: 0 auto 20px auto;
}
.faq__search_container {
  display: flex;
  justify-content: center;
  margin: 42px 0 61px 0;
}
.faq__item_img {
  width: 100%;
  margin-top: 20px;
}
.faq__contact {
  max-width: 808px;
  margin: 80px auto 40px auto;
  background: radial-gradient(
    50% 50% at 50% 10%,
    rgba(106, 77, 253, 0.4) 0%,
    rgba(106, 77, 253, 0) 100%
  );
  border: 0.860352px solid rgba(255, 255, 255, 0.33);
  border-radius: 10.3242px;
}
.faq__contact_text {
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  color: white;
  text-align: center;
  margin-top: 30px;
}
.faq__contact_link {
  border: 1px solid rgba(255, 255, 255, 0.33);
  border-radius: 4px;
  margin: 27px auto 28px auto;
  padding: 12px 30px;
  color: white !important;
  width: fit-content;
  font-size: 16px;
}
.faq__contact_link a {
  color: white;
  font-weight: 700;
  letter-spacing: 0.15em;
}
@media (max-width: 830px) {
  .faq__container {
    padding: 0 10px;
  }
}
@media (max-width: 650px) {
  .faq__title {
    font-size: 24px;
    line-height: 28px;
  }
  .faq__description {
    max-width: 425px;
  }
  .faq__search_container {
    margin: 0 0 40px 0;
  }
  .faq__container {
    margin-top: 40px;
  }
}
