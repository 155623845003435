.offer__container {
  max-width: 1224px;
  margin-top: 70px;
  margin-bottom: 70px;
  padding: 0px;
  z-index: 999;
  width: 100%;
  overflow: auto;
}
.title {
  font-size: 40px;
  color: #fff;
  font-weight: 700;
}
.item__container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media (max-width: 450px) {
  .offer__container {
    margin-top: 30px;
  }
}
@media (max-width: 1235px) {
  .offer__container {
    padding: 0 10px;
  }
}
@media (max-width: 576px) {
  .title {
    font-size: 35px;
    text-align: center;
  }
}
