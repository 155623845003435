.digital__item__block {
  margin-top: 12px;
  border-radius: 12px;
}
.top_part {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px 12px 0 0;
  display: flex;
  align-items: center;
}
.ticket_item {
  padding: 10px 14px;
  background: radial-gradient(
    50% 50% at 0% 0%,
    rgba(5, 255, 0, 0.33) 0%,
    rgba(5, 255, 0, 0) 210%
  );
  border-radius: 12px 0 0 0;
}
.service_item {
  padding: 10px 14px;
  background: radial-gradient(
    50% 50% at 0% 0%,
    rgba(255, 229, 0, 0.33) 0%,
    rgba(255, 229, 0, 0) 210%
  );
  border-radius: 12px 0 0 0;
}
.digital_item {
  padding: 10px 14px;
  background: radial-gradient(
    50% 50% at 0% 0%,
    rgba(0, 240, 255, 0.33) 0%,
    rgba(0, 240, 255, 0) 210%
  );
  border-radius: 12px 0 0 0;
}
.item_title {
  font-weight: 700;
  font-size: 14px;
  margin: 0;
  color: #fff;
  letter-spacing: 0.15em;
  text-transform: uppercase;
}
.ticket_content {
  position: relative;
  display: flex;
  width: 100%;
  overflow: hidden;
  padding: 14px 12px 20px 12px;
  background: rgba(255, 255, 255, 0.03);
  z-index: 999;
  gap: 22px;
}
@media (max-width: 991px) {
  .ticket_content {
    flex-direction: column;
  }
}
.item_avatar {
  width: 100px;
  height: 100px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
}
.item_avatar img {
  width: 100px;
  height: 100px;
  border-radius: 8px;
}
.item_name {
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0;
}
.item_location {
  color: rgba(255, 255, 255, 0.66);
  font-size: 14px;
  margin-bottom: 18px;
}
.item_btn {
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 0px;
  padding: 13px 18px;
  line-height: 1;
  letter-spacing: 0.03em;
  color: #fff;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 50px;
  height: 24px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.item_btn:hover {
  background: rgba(255, 255, 255, 0.35);
}
.view_btn {
  margin-left: 5px;
  text-transform: uppercase;
}
.button_group {
  display: flex;
  gap: 8px;
  flex-direction: column;
  justify-content: center;
}
.download_button {
  border: 1px solid rgba(255, 255, 255, 0.33);
  border-radius: 4px;
  padding: 10px 30px;
  color: white;
  font-weight: 700;
  font-size: 14px;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}
.download_button:hover {
  border: 1px solid rgba(255, 255, 255, 1);
}
.button_block {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.created_txt {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 0;
  color: rgba(255, 255, 255, 0.66);
  text-transform: uppercase;
}
.user_info {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 0;
  color: white;
}
.user__info_email {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;
  color: white;
}
.bottom__line {
  background: rgba(255, 255, 255, 0.15);
  height: 1px;
}
.foot_part {
  padding: 0 12px 20px 12px;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 0 0 12px 12px;
}
.bottom__container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  align-items: center;
  flex-direction: row-reverse;
}
.bottom__button {
  border: 1px solid rgba(255, 255, 255, 0.33);
  border-radius: 4px;
  padding: 10px 30px;
  color: white;
  font-weight: 700;
  font-size: 14px;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}
.bottom__button:hover {
  border: 1px solid rgba(255, 255, 255, 1);
}
.bottom__buttons {
  display: flex;
  gap: 10px;
}
@media (max-width: 1200px) {
  .bottom__buttons {
    flex-direction: column;
  }
  .bottom__container {
    align-items: flex-start;
  }
}
.purchased_time {
  display: flex;
  margin-top: 0px;
}
.ticket__content_block {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 450px) {
  .ticket__content_block {
    flex-direction: column;
    gap: 25px;
  }
  .bottom__container {
    flex-direction: column;
    gap: 16px;
  }
  .bottom__buttons {
    width: 100%;
  }
  .bottom__button {
    font-size: 12px;
    padding: 10px 15px;
  }
}
@media (max-width: 991px) {
  .ticket__title_block {
    display: none;
  }
  .ticket__title_block_sm {
    display: block !important;
  }
}
.ticket__img_block {
  display: flex;
  gap: 20px;
}
@media (max-width: 350px) {
  .ticket__img_block {
    flex-direction: column;
  }
}
.ticket__title_block_sm {
  display: none;
}
.created_purchased {
  display: flex;
  gap: 42px;
}
@media (max-width: 1200px) {
  .created_purchased {
    flex-direction: column;
    gap: 12px;
  }
}
.offer_notification {
  width: 14px;
  height: 14px;
  background: rgba(255, 0, 0, 1);
  border-radius: 14px;
  margin-left: 6px;
  font-size: 10px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
}
.video {
  width: 100px;
  height: 100px;
  border-radius: 8px;
  object-fit: cover;
}
